import { Helmet } from "react-helmet";
import Hero from "../components/home/Hero";
import Services from "../components/home/Services";
import Tools from "../components/home/Tools";
import TrustedCompany from "../components/home/TrustedCompany";
import Blogs from "../components/shared/Blogs";
import FAQ from "../components/shared/FAQ";

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>Saintsmen Systems | Software Development & IT solutions</title>
        <meta
          name="description"
          content="Saintsmen Systems is a software development company that specialises in custom web and mobile app development. We deliver quality with excellence."
        />
      </Helmet>

      <div className="hero-gradient max-w-screen">
        <Hero />
        <TrustedCompany />
      </div>

      <div className="key-features-gradient">
        <Tools />
        <Services />
      </div>

      <Blogs />
      <FAQ isHome={true} />
    </>
  );
};

export default HomePage;
