import { useEffect } from "react";
import TermsAndConditions from "../components/terms-and-conditions/TermsAndConditions";
import { Helmet } from "react-helmet";

export default function TermsAndConditionsPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet>
        <title>Saintsmen Systems | Terms And Conditions</title>
        <meta
          name="description"
          content="Review our Terms and Conditions: Learn the rules and guidelines for using our services and website."
        />
      </Helmet>
      <TermsAndConditions />
    </>
  );
}
