import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";

import Email from "../../assets/footer/email.svg";
import Telephone from "../../assets/footer/telephone.svg";
import Location from "../../assets/footer/location.svg";
import Logo from "../../assets/visibla-logo.svg";
import { Link } from "react-router-dom";
import Iso_logo from '../../assets/footer/Footer_LOGO_1.png'
import Iaf_logo from '../../assets/footer/Footer_IAF.png'
const Footer = () => {
  const currentYear = new Date().getFullYear();
  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="bg-footerBackground text-gray-300 px-4 md:px-8 lg:px-16 py-8 lg:pt-16 w-full">
      <div className="mx-auto grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5 lg:gap-8">
        {/* Visibla Solutions Logo and Description */}
        <div className="col-span-1 text-center md:text-left md:col-span-2 lg:col-span-1">
          <Link to="/" onClick={handleLogoClick}>
            <img
              src={Logo}
              alt="Saintsmen Systems"
              className="h-20 mx-auto md:mx-0 w-40"
              loading="lazy"
            />
          </Link>
          <p className="mb-4 text-neutral3 text-sm lg:text-base">
            Accelerate business growth through the best software, apps and
            website developers in Asia.
          </p>
          {/* Social media links */}
          <div className="flex flex-col ">
          <div className="flex justify-center md:justify-start space-x-4">
            <Link
              // to="https://www.facebook.com/visiblasolutions"
              aria-label="Facebook"
              className="text-white"
              target="_blank"
            >
              <FaFacebookF />
            </Link>
            <Link
              // to="https://twitter.com/visiblasolution"
              aria-label="Twitter"
              className="text-white"
              target="_blank"
            >
              <FaTwitter />
            </Link>
            <Link
              // to="https://www.instagram.com/visiblasolutions"
              aria-label="Instagram"
              className="text-white"
              target="_blank"
            >
              <FaInstagram />
            </Link>
            <Link
              // to="https://www.linkedin.com/company/visiblasolutions"
              aria-label="LinkedIn"
              className="text-white"
              target="_blank"
            >
              <FaLinkedin />
            </Link>
          
          </div>
          <div className="flex items-center justify-center md:justify-start ml-[-4px] my-4 space-x-4">
            <img src={Iso_logo} alt="" className="mt-[8px]" />
            <img src={Iaf_logo} alt="" className="" />
          </div>
            
            

          </div>
         
        </div>
        {/* Overview and Services in second row for mobile */}
        <div className="flex gap-16 md:grid grid-cols-1 md:grid-cols-2 md:gap-4 col-span-1 md:col-span-2 lg:col-span-2">
          <div className="text-left">
            <h3 className="text-base md:text-lg font-semibold text-white mb-4">
              Overview
            </h3>
            <ul className="text-xs md:text-base">
              <li className="mb-2">
                <Link to="/about-us" className="text-neutral3">
                  About us
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/careers" className="text-neutral3">
                  Careers
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/case-study" className="text-neutral3">
                  Case studies
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/blogs" className="text-neutral3">
                  Blogs
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/news" className="text-neutral3">
                  News
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/contact-us" className="text-neutral3">
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
          <div className="ml-1 md:ml-0 text-left">
            <h3 className="text-base md:text-lg font-semibold text-white mb-4">
              Services
            </h3>
            <ul className="text-xs md:text-base">
              <li className="mb-2">
                <Link to="/services" className="text-neutral3">
                  Website Development
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/services" className="text-neutral3">
                  App Development
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/services" className="text-neutral3">
                  Custom Software <br /> Development
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/services" className="text-neutral3">
                  AI & ML Services
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/services" className="text-neutral3">
                  Talent Resourcing
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/services" className="text-neutral3">
                  Consulting
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* Industries and Contact Details in third row for mobile */}
        <div className="flex gap-16 md:grid grid-cols-1 md:grid-cols-2 md:gap-4 col-span-1 md:col-span-2 lg:col-span-2">
          <div className="text-left">
            <h3 className="text-base md:text-lg font-semibold text-white mb-4">
              Industries
            </h3>
            <ul className="text-xs md:text-base">
              <li className="mb-2">
                <Link to="/industries" className="text-neutral3">
                  FinTech
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/industries" className="text-neutral3">
                  Travel
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/industries" className="text-neutral3">
                  Logistics
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/industries" className="text-neutral3">
                  Supply Chain
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/industries" className="text-neutral3">
                  E-Commerce
                </Link>
              </li>
            </ul>
          </div>
          <div className="text-left">
            <h3 className="text-base md:text-lg font-semibold text-white mb-4">
              Contact Us
            </h3>
            <ul className="text-xs md:text-base">
              <li className="mb-2 flex md:justify-start items-center">
                <img src={Email} alt="email" className="mr-2" />
                <a
                  href="mailto:sales@saintsmen.com"
                  className="text-neutral3"
                >
                  sales@saintsmen.com
                </a>
              </li>
              <li className="mb-2 flex md:justify-start items-center">
                <img src={Telephone} alt="telephone" className="mr-2" />
                <a href="tel:+919742116367" className="text-neutral3">
                  +91-9742116367
                </a>
              </li>
              <li className="mb-2 flex md:justify-start items-start">
                <img src={Location} alt="location" className="mr-2 pt-1" />
                <span className="text-neutral3 text-left">
                  2nd Floor, Anthill IQ 75/B, Windsor F4, Bannerghatta Rd,
                  Opposite Christ University, Hulimavu, Bengaluru-Karnataka
                  560076, India
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mx-auto mt-8 border-t border-gray-700 pt-4 lg:pt-8 flex flex-col items-center md:flex-row md:justify-between text-center md:text-left">
        <p className="text-sm text-neutral3 mb-4 md:mb-0">
          Copyright &copy; {currentYear} Saintsmen Systems
        </p>
        <div className="flex flex-col md:flex-row gap-2 text-sm text-neutral3">
          <span>All Rights Reserved</span>
          <div className="flex gap-2">
            <span className="hidden md:block border-l border-neutral3 h-auto mx-2"></span>
            <Link to="/terms-and-conditions" className="text-white underline">
              Terms and Conditions
            </Link>
            <span className="block border-l border-neutral3 h-auto mx-2"></span>
            <Link to="/privacy-policy" className="text-white underline">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
