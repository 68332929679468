import React, { useEffect, useRef, useState } from "react";
import SectionTitle from "../shared/SectionTitle";
import { Link, useNavigate } from "react-router-dom";
import Button from "../shared/Button";
import Services from "../../data/Services";
import Separator from "../../assets/services/separator.svg";
import ScrollToTop from "../shared/ScrollToTop";

const ServicesMain = () => {
  const serviceRefs = useRef([]);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const handleScroll = () => {
      setShowScrollToTop(window.scrollY > 300);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const keyWords = [
    "Software Development Services",
    "AI & ML",
    "Staffing and Talent Resourcing",
    "Digital Marketing and Branding",
    "Component and Material Sourcing",
    "Data Management and Analytics",
    "System Integration and Maintenance",
    "Cloud and DevOps",
    "Blockchain Solutions",
    "Software Testing and Quality Assurance",
    "Consulting"
  ];

  const handleKeywordClick = (index) => {
    serviceRefs.current[index].scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="mb-10 px-2 md:px-8 breakthrough-gradient">

      <div className="">

    
     

      <h1 className="font-semibold text-[32px] md:text-[40px] mt-4 text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary">
        Build, Optimize, Grow
      </h1>
      <p className="mt-4 text-white text-center">
        From custom software to talent sourcing, we deliver quality - fast.
      </p>
      <Button
        onClick={() => navigate("/contact-us")}
        buttonText="Opt for a Discovery Call"
        className="mt-4"
      />
        </div>

      <div className="flex flex-wrap gap-4 my-4 mt-12 md:mt-24 justify-center">
        {keyWords.map((keyword, index) => (
          <button
            key={index}
            className="text-[14px] md:text-base px-4 md:px-8 py-2 md:py-3 rounded-full border border-gray18 bg-footerBackground text-neutral3 hover:text-white hover:bg-transparent hover:bg-primary hover:border-primary"
            onClick={() => handleKeywordClick(index)}
          >
            {keyword}
          </button>
        ))}
      </div>
      {Services.map((service, index) => (
        <div
          key={index}
          className="mt-24 flex flex-col md:flex-row"
          ref={(el) => (serviceRefs.current[index] = el)}
        >
          <div className="md:w-1/2 flex flex-col pr-2">
            <div className="md:w-[510px] h-auto mb-4">
              <h1 className="text-white font-semibold text-2xl md:text-4xl text-left">
                {service.title}
              </h1>
              <p className="mt-4 text-white text-medium text-lg text-left">
                {service.caption}
              </p>
            </div>
            <div className="flex flex-wrap justify-start items-center gap-3 mb-2 pr-2">
              {service.items.map((tech, index) => (
                <SectionTitle
                  key={index}
                  sectionText={tech.name}
                  onClick={()=>{navigate(`/services/${tech.id}`)}}
                  
                />
              ))}
            </div>
          </div>
          <div className="md:w-1/2 flex-grow flex items-center">
            <img
              src={service.image}
              alt={service.title}
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      ))}

      {showScrollToTop && <ScrollToTop />}
    </div>
  );
};

export default ServicesMain;
