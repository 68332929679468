import React from "react";
import industriesData from "../../data/Industries";
import SectionTitle from "../shared/SectionTitle";
import { useEffect } from "react";

export default function Industries() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>

      <h1 className="text-2xl md:text-[40px] font-semibold text-transparent bg-clip-text bg-gradient-to-r from-accent1 to-tertiary text-center mt-4 mb-4 md:mb-8">
        Tailored Solutions for Your Specific Needs
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        {industriesData.map((industry, index) => (
          <div key={index} className="text-white bg-black py-4 text-left">
            <div className="relative">
              <img
                src={industry.image}
                alt={`${industry.title}`}
                className="w-full h-auto"
                loading="lazy"
              />
            </div>
            <div className="">
              <img
                src={industry.icon}
                alt={`${industry.title}`}
                className="w-10 h-10 mt-4 mb-3"
                loading="lazy"
              />
              <h2 className="text-[28px] font-semibold">{industry.title}</h2>
              <hr />
              <p className="text-[18px] mt-2">{industry.info}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
