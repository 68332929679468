import React from "react";

import AWS from "../../assets/technology-partners/aws.svg";
import GoogleCloud from "../../assets/technology-partners/google-cloud.svg";
import Microsoft from "../../assets/technology-partners/microsoft.svg";
import Adobe from "../../assets/technology-partners/adobe.svg";

const TechnologyPartners = () => {
  return (
    <div className="mt-4">
      <h1 className="text-white text-lg font-semibold">
        Our Technological Partners
      </h1>
      <div className="flex gap-4 mt-4">
        <div className="bg-technologyPartner h-14 w-28 flex justify-center items-center border-solid border-neutral3 border-[0.15px] rounded-md p-2">
          <img src={AWS} alt="aws" className="h-9" />
        </div>
        <div className="bg-technologyPartner h-14 w-28 flex justify-center items-center border-solid border-neutral3 border-[0.15px] rounded-md p-2">
          <img src={GoogleCloud} alt="google-cloud" className="h-9" />
        </div>
        <div className="bg-technologyPartner h-14 w-28 flex justify-center items-center border-solid border-neutral3 border-[0.15px] rounded-md p-2">
          <img src={Microsoft} alt="microsoft" className="h-9" />
        </div>
        <div className="bg-technologyPartner h-14 w-28 flex justify-center items-center border-solid border-neutral3 border-[0.15px] rounded-md p-2">
          <img src={Adobe} alt="adobe" className="h-9" />
        </div>
      </div>
    </div>
  );
};

export default TechnologyPartners;
