import NewsMain from "../components/news/NewsMain";
import { Helmet } from "react-helmet";

export default function NewsPage() {
  return (
    <>
      <Helmet>
        <title>Saintsmen Systems | News</title>
        <meta
          name="description"
          content="Get the latest updates on IT development, custom software, web, and mobile app solutions with our news. Stay ahead with the newest industry insights."
        />
      </Helmet>
      <NewsMain />
    </>
  );
}
