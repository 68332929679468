import BlogsMain from "../components/blogs/BlogsMain";
import { Helmet } from "react-helmet";

export default function BlogsPage() {
  return (
    <>
      <Helmet>
        <title>Saintsmen Systems | Blogs</title>
        <meta
          name="description"
          content="Follow our blog for insights on custom software, web, and mobile app development. Stay informed about IT trends and innovations."
        />
      </Helmet>
      <BlogsMain />
    </>
  );
}
