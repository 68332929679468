import AboutUs from "../components/about-us/AboutUs";
import { Helmet } from "react-helmet";

export default function AboutUsPage() {
  return (
    <>
      <Helmet>
        <title>Saintsmen Systems | About Us</title>
        <meta
          name="description"
          content="Saintsmen Systems is a software development company founded in the year 2023. We firmly believe in quality and excellence."
        />
      </Helmet>
      <AboutUs />
    </>
  );
}
