import SoftwareDevServices from '../assets/services/software-development-services.png'
import AIMLServices from '../assets/services/artificial-intelligence-and-machine-learning.png'
import StaffingTalentResourcing from '../assets/services/staffing-and-talent-resourcing.png'
import DigitalMarketingBranding from '../assets/services/digital-marketing-and-branding.png'
import ComponentMaterialSourcing from '../assets/services/componet-and-material-sourcing.png'
import DataManagementAnalytics from '../assets/services/data-management-and-analytics.png'
import SystemIntegration from '../assets/services/system-integration-and-maintainence.png'
import CloudandDevops from '../assets/services/devops.png'
import BlockChainServices from '../assets/services/details/blockchain-services.svg'
import QualityAssurance from '../assets/services/softwaretesting.png'
import Consulting from '../assets/services/consulting.png'
const Services = [
  {
   
    title: "Software Development Services",
    image: SoftwareDevServices,
    caption:
      "Custom software solutions tailored to your business needs,ensuring efficiency,scalability and innovation.",
    items: [{name:"Web App Development",id:"web-app-development"},{name:"Mobile App Development",id:"mobile-app-development"},{name:"Custom App Development",id:"custom-software-development"},{name:"No Code Web Development",id:'no-code-web-development'}],
  
  },

  {

    title: "Artificial Intelligence and Machine Learning",
    image: AIMLServices,
    caption:
      "AI and ML solutions transforming data into actionable insights for smarter,automated decision-making and innovation.",
    items: [{name:'AI and ML services',id:"ai-and-ml-services"},{name:"Unlock AI-Driven Transformation",id:"unlock-ai-driven-transformation"},{name:"Custom-built AI Development",id:"custom-built-ai-development"},{name:"Deep Learning",id:"deep-learning"},{name:"AI Conversational Tools",id:'ai-conversational-tools'},{name:'Actionable Intelligence',id:"actionable-intelligence"}],
  
  },

  {
    
    title: "Staffing and Talent Resourcing",
    image: StaffingTalentResourcing,
    caption:
      "Providing top-tier staffing and talent resourcing to meet your business's dynamic workforce needs efficiently.",
    items: [{name:'Staff Augmentation',id:"staff-augmentation"},{name:"Talent Resourcing",id:"talent-resourcing"},{name:"Engineering and Technical Staffing",id:"engineering-and-technical-staffing"},{name:"Professional Staffing",id:"professional-staffing"}],
   
  },

  {
    title: "Digital Marketing and Branding",
    image: DigitalMarketingBranding,
    caption:
      "Enhancing your brand's online presence through strategic digital marketing and compelling branding solutions.",
    items:[{name:"Digital Marketing and Branding",id:"digital-marketing-and-branding"},{name:"Lead Generation",id:"lead-generation"},{name:"Data Appending",id:"data-appending"},{name:"Contact Appending",id:"contact-appending"},{name:"Technology Lists",id:"technology-lists"},{name:"Industry Specific Lists",id:"industry-specific-lists"}]
    
  },

  {
   
    title: "Component and Material Sourcing",
    image: ComponentMaterialSourcing,
    caption:
      "Reliable component and material sourcing to ensure quality and timely delivery for your manufacturing needs.",
    items:[{name:"Aircraft Component",id:"aircraft-component"},{name:"Industrial Machinery Spares",id:"industrial-machinery-spares"},{name:"Marine Grade Component",id:"marine-grade-component"},{name:"Space Grade Component",id:"space-grade-component"},{name:"Parts for Defence Industry",id:"parts-for-defence-industry"}]
  },

  {
    title: "Data Management and Analytics",
    image: DataManagementAnalytics,
    caption:
      "Optimizing data management and analytics for informed decision-making and business growth.",
    items: [{name:"Master Data Management",id:"master-data-migration"},{name:"Data Warehousing & Business Intelligence",id:"data-warehousing-and-business-intelligence"}],
 
  }
  ,{
    title:"System Integration and Maintenance",
    image:SystemIntegration,
    caption:"Seamless system integration and maintenance to ensure optimal performance and reliability.",
    items:[{name:"Legacy Systems Maintenance & Migration",id:"legacy-systems-maintenance-and-migration"},{name:"Enterprise Application Integration",id:"enterprise-application-integration"}]
  },
  {
    title:"Cloud and DevOps",
    image:CloudandDevops,
    caption:"Streamlining operations with cloud solutions and DevOps for enhanced scalability and efficiency.",
    items:[{name:"Cloud & DevOps",id:"cloud-and-devops"}]
  },
  {
    title:"Blockchain Solutions",
    image:BlockChainServices,
    caption:"Innovative blockchain solutions for secure,transparent, and decentralized applications.",
    items:[{name:"Blockchain Solutions",id:"block-chain-solutions"}]
  },
  {
    title:"Software Development & Quality Assurance",
    image:QualityAssurance,
    caption:"Ensuring excellence with comprehensive quality assurance for reliable and high-performing solutions.",
    items:[{name:"Software Testing",id:"software-testing"}]
  },
  {
    title:"Consulting",
    image:Consulting,
    caption:"Expert consulting services to drive strategic growth and operational excellence.",
    items:[{name:"Consulting",id:"consulting"}]
  }
];

export default Services;
