import Careers from "../components/careers/Careers";
import { Helmet } from "react-helmet";

export default function CareersPage() {
  return (
    <>
      <Helmet>
        <title>Saintsmen Systems | Careers</title>
        <meta
          name="description"
          content="Become a part of our team: Explore careers in software development, design, marketing, sales and many more. Apply today."
        />
      </Helmet>
      <Careers />
    </>
  );
}
