import SectionTitle from "../shared/SectionTitle";

export default function PrivacyPolicy() {
  return (
    <div className="text-white py-4 md:py-8 px-2 text-left">
      <SectionTitle
        sectionText="Saintsmen Systems"
        className="justify-center"
      />
      <h1 className="my-4 md:my-8 text-center text-xl md:text-[40px] font-semibold">
        Privacy Policy
      </h1>

      <main className="space-y-8">
        <section>
          <h2 className="text-base md:text-[28px] font-semibold mb-2">
            Your Privacy is Our Priority
          </h2>
          <hr />
          <p className="mt-2 text-xs md:text-lg font-normal">
            At Saintsmen Systems, we understand the importance of protecting
            your privacy. This Privacy Policy outlines how we collect, use, and
            disclose your personal information when you visit our website. We
            are committed to being transparent about our data practices and
            ensuring your information is handled responsibly.
          </p>
        </section>

        {/* Information we collect section */}
        <section>
          <h2 className="text-base md:text-[28px] font-semibold mb-2">
            Information We Collect
          </h2>
          <hr />
          <p className="mt-2 text-xs md:text-lg font-normal">
            We collect information to provide you with a smooth and personalised
            experience on our website. This information falls into two
            categories:
          </p>
          <ul className="list-disc list-inside mt-2 text-xs md:text-lg font-normal px-4 md:px-8">
            <li>
              Automatically Collected Data: When you visit our website, we
              automatically collect information about your device and browsing
              activity. This may include your IP address, operating system,
              browser type, the pages you visit, and the links you click.
            </li>
            <li className="mt-2">
              Information You Provide: We also collect information that you
              choose to share with us. This could include your name, email
              address, phone number, and any other details you submit through
              contact forms or inquiries.
            </li>
          </ul>
        </section>

        {/* Boundaries section */}
        <section>
          <h2 className="text-base md:text-[28px] font-semibold mb-2">
            We Respect Your Boundaries
          </h2>
          <hr />
          <p className="mt-2 text-xs md:text-lg font-normal">
            We understand that certain information is highly sensitive. Here’s
            what we don’t collect:
          </p>
          <ul className="list-disc list-inside mt-2 px-4 md:px-8 text-xs md:text-lg">
            <li>
              Financial Information: We prioritise your financial security. We
              do not store any credit card or bank account details during
              transactions.
            </li>
          </ul>
        </section>

        {/* Cookies section */}
        <section>
          <h2 className="text-base md:text-[28px] font-semibold mb-2">
            Cookies: Understanding Your Preferences
          </h2>
          <hr />
          <p className="mt-2 text-xs md:text-lg font-normal">
            We use cookies, small data files stored on your device, to enhance
            your experience on our website. These cookies serve various
            purposes:
          </p>
          <ul className="list-disc list-inside mt-2 text-xs md:text-lg font-normal px-4 md:px-8">
            <li>
              Essential Cookies: These cookies are necessary for basic website
              functionality, such as remembering your login credentials and form
              submissions. Disabling them may limit your ability to use certain
              features.
            </li>
            <li className="mt-2">
              Performance Cookies: These cookies help us understand how visitors
              interact with our website. They provide insights into user
              behaviour and website performance, allowing us to make
              improvements for a better user experience.
            </li>
            <li className="mt-2">
              Personalization Cookies: These cookies allow us to personalise
              your website experience by remembering your preferences and past
              interactions. For example, they might tailor content
              recommendations based on your interests.
            </li>
          </ul>
          <p className="mt-2 text-xs md:text-lg font-normal">
            You have control over cookies. Your web browser settings allow you
            to manage and disable cookies. However, keep in mind that doing so
            may affect some website functionalities.
          </p>
          <p className="mt-2 text-xs md:text-lg font-normal">
            How We Use Your Information
          </p>
          <p className="mt-2 text-xs md:text-lg font-normal">
            The information we collect is used for legitimate purposes, always
            striving to improve your experience with Saintsmen Systems:
          </p>
          <ul className="list-disc list-inside mt-2 text-xs md:text-lg font-normal px-4 md:px-8">
            <li>
              Respond to Inquiries: We use your contact information to respond
              to your questions, requests, and provide customer support.
            </li>
            <li className="mt-2">
              Enhance Your Experience: We may use information like browsing
              history to personalise your website experience and recommend
              relevant content.
            </li>
            <li className="mt-2">
              Improve Our Services: Website usage data helps us understand how
              visitors interact with our platform. This allows us to identify
              areas for improvement and enhance the overall functionality and
              user experience.
            </li>
            <li className="mt-2">
              Marketing Communications (with your consent): We may use your
              contact information to send you marketing materials about our
              services. However, we will only do so if you have explicitly
              opted-in to receive such communications. You can always
              unsubscribe from our mailing list at any time.
            </li>
          </ul>
        </section>

        {/* Sharing your info section */}
        <section>
          <h2 className="text-base md:text-[28px] font-semibold mb-2">
            Sharing Your Information Responsibly
          </h2>
          <hr />
          <p className="mt-2 text-xs md:text-lg font-normal">
            We are committed to protecting your privacy and will not sell,
            trade, or rent your personal information to third parties. However,
            we may share aggregated data (without any personal identifiers) with
            partners for marketing and analytical purposes.
          </p>
        </section>

        {/* External Links section */}
        <section>
          <h2 className="text-base md:text-[28px] font-semibold mb-2">
            External Links and Third-Party Practices
          </h2>
          <hr />
          <p className="mt-2 text-xs md:text-lg font-normal">
            Our website may contain links to third-party websites. It's
            important to note that we are not responsible for the privacy
            practices of these external sites. We encourage you to review their
            privacy policies before using them.
          </p>
        </section>

        {/* Keeping you informed section */}
        <section>
          <h2 className="text-base md:text-[28px] font-semibold mb-2">
            Keeping You Informed: Updates to This Policy
          </h2>
          <hr />
          <p className="mt-2 text-xs md:text-lg font-normal">
            We may update this Privacy Policy from time to time to reflect
            changes in our data practices or legal requirements. We will revise
            the "last updated" date at the top of this Policy whenever changes
            are made. We encourage you to periodically review this Policy to
            stay informed about how we handle your personal information.
          </p>
        </section>

        {/* Your acceptance section */}
        <section>
          <h2 className="text-base md:text-[28px] font-semibold mb-2">
            Your Acceptance of This Policy
          </h2>
          <hr />
          <p className="mt-2 text-xs md:text-lg font-normal">
            By using our website, you signify your acceptance of this Privacy
            Policy. If you do not agree to this Policy, please refrain from
            using our website. Your continued use of the website following the
            posting of changes to this Policy constitutes your acceptance of
            those changes.
          </p>
        </section>

        {/* Contact Us section */}
        <section>
          <h2 className="text-base md:text-[28px] font-semibold mb-2">
            Contact Us
          </h2>
          <hr />
          <p className="mt-2 text-xs md:text-lg font-normal">
            If you have any questions about this Privacy Policy or our data
            practices, please don't hesitate to contact us. We are committed to
            transparency and addressing any concerns you may have.
          </p>
        </section>
      </main>
    </div>
  );
}
