import NewsDetails from "../components/news-details/NewsDetails";
import { Helmet } from "react-helmet";

export default function NewsDetailsPage() {
  return (
    <>
      <Helmet>
        <title>Saintsmen Systems | News</title>
        <meta
          name="description"
          content="Stay informed with our news: Updates on IT development, custom software, web, and mobile app solutions. Explore the latest industry news."
        />
      </Helmet>
      <NewsDetails />
    </>
  );
}
