import React from "react";
import CaseStudyMain from "../components/case-study/CaseStudyMain.jsx";
import { Helmet } from "react-helmet";

const CaseStudyPage = () => {
  return (
    <>
      <Helmet>
        <title>Saintsmen Systems | Case Studies</title>
        <meta
          name="description"
          content="Discover success stories. IT Development case studies showcasing custom software, web, mobile app and blockchain development solutions across industries"
        />
      </Helmet>
      <CaseStudyMain />
    </>
  );
};

export default CaseStudyPage;
