import CustomSoftwareDevelopment from "../assets/services/details/custom-software-development.svg";
import WebDevelopment from "../assets/services/details/web-development.svg";
import CloudDevops from "../assets/services/details/cloud-devops.svg";
import MobileAppDevelopment from "../assets/services/details/mobile-app-development.svg";
import SoftwareTesting from "../assets/services/details/software-testing.svg";
import AIMLServices from "../assets/services/details/ai-ml-services.svg";
import BlockChainServices from "../assets/services/details/staff-augumentation.svg";
import StaffAugumentation from "../assets/services/details/blockchain-services.svg";
import DigitalBranding from "../assets/services/details/digital-branding-marketing.svg";
import DigitalBranding2 from "../assets/services/details/digital-branding2.svg";
import Consulting from "../assets/services/details/consulting.svg";
import MasterDataManagement from "../assets/services/details/master-data-management.svg";
import DataWarehouseManagement from "../assets/services/details/data-warehouse-business-intelligence.svg";
import LegacySystems from "../assets/services/details/legacy-systems-maintenance.svg";
import EnterpriseApplicationIntegration from "../assets/services/details/enterprise-application-integration.svg";
import EngineeringTechnicalStaffing from "../assets/services/details/engineering-technical-staffing.svg";
import ProfessionalStaffing from "../assets/services/details/professional-staffing.svg";
import NoCodeWebsiteDevelopment from '../assets/services/details/no-code-website-development.png'
import UnlockAI from '../assets/services/details/unlock-ai-driven.png'
import CustomBuiltAIDevelopment from '../assets/services/details/custom-built-ai-development.png'
import DeepLearning from '../assets/services/details/deep-learning.png'
import AIConversationalTools from '../assets/services/details/ai-conversational-tools.png'
import ActionableIntelligence from '../assets/services/details/actionable-intelligence.png'
import DigitalMarketingBranding from '../assets/services/details/digital-branding-marketing.svg'
import LeadGeneration from '../assets/services/details/lead-generation.png'
import DataAppending from '../assets/services/details/data-appending.png'
import ContactAppending from '../assets/services/details/contact-appending.png'
import IndustrySpecificLists from '../assets/services/details/industry-specific-lists.png'
import AirCraftComponentSourcing from '../assets/services/details/aircraft-component-sourcing.png'
import IndustrialComponentSourcing from '../assets/services/details/industrial-component-sourcing.png'
import MarineComponentSourcing from '../assets/services/details/marine-component-sourcing.png'
import SpaceComponentSourcing from '../assets/services/details/space-component-sourcing.png'
import DefenceComponentSourcing from '../assets/services/details/defence-component-sourcing.png'
import TechnologyLists from '../assets/services/details/technology-lists.png'
const ServicesDetails = [
  {
    id: 'technology-lists',
    serviceHeader: "Technology Lists",
    content:
      "We are a leading provider of Technology User Lists, Business Technology Databases, B2B Technology Lists, and Technology Database Marketing Services. Our services help organizations acquire and retain quality, targeted customers through accurate and comprehensive technology lists. Our extensive database includes millions of records covering a wide range of technologies, providing detailed contact information for users of those technologies. These records are periodically updated, with new entries added as we discover new users and technologies.",
    image:TechnologyLists,
    keyFeatures: {
      feature: [
        "1. Comprehensive Database: Our extensive technology user lists cover a wide range of technologies, providing detailed contact information for targeted marketing.",
        "2. Accurate and Updated Records: We ensure our records are continually updated and accurate, helping you reach the right audience effectively.",
        "3. Targeted Marketing: Our technology lists are designed to help you acquire and retain quality customers, enhancing your marketing efforts and boosting your ROI.",
        "4. Flexible and Scalable Solutions: Our technology lists and services are flexible and scalable to meet the evolving needs of your business.",
        "5. Secure Data Handling: We prioritize data security and ensure compliance with industry standards to protect your information.",
        "6. Customer Support: Our dedicated support team is available to assist you with any queries or issues, ensuring a smooth experience."
      ],
    },
    infrastructure: {
      title:
        "Our infrastructure is built on reliable and scalable cloud platforms, ensuring optimal performance and flexibility for managing and utilizing technology lists.",
      points: [],
    },
    techStacks: {
      title: "Technology Specific Lists Process",
      array: [
        "Requirement Gathering & Data Collection: We collaborate with clients to gather specific requirements and source data from verified databases, ensuring high accuracy and relevance.",
        "Data Verification & Segmentation: Our team conducts rigorous checks to verify the data's authenticity and segments it based on the gathered criteria to create targeted lists.",
        "List Compilation & Quality Assurance: The segmented data is compiled into comprehensive technology lists, followed by thorough quality checks to ensure accuracy and up-to-date information.",
        "Delivery & Ongoing Updates: The final technology lists are securely delivered to you, with regular updates provided to maintain their accuracy and effectiveness."
      ],
    },
    developmentTechniques: {
      title: "Listing Techniques",
      array: [
        "1. Data Mining: Use advanced data mining techniques to extract relevant information from large datasets.",
        "2. Web Scraping: Implement web scraping tools to gather data from various online sources and websites.",
        "3. Database Integration: Integrate various databases to compile comprehensive and detailed technology lists.",
        "4. Manual Research: Conduct manual research to verify and enhance the accuracy of the data.",
        "5. Survey and Feedback: Collect data through surveys and feedback forms to ensure updated and relevant contact information.",
        "6. API Integration: Use API integration to continuously update and maintain the accuracy of the technology lists."
      ],
    },
    developmentCycle: {
      title: "Selection Criteria",
      array:  [
        "Our Technology Lists can be customized based on various criteria to suit your specific business needs",
        "· Company Name",
        "· Contact Name",
        "· Job Title",
        "· Mailing Address",
        "· City",
        "· State",
        "· ZIP Code",
        "· Phone & Fax Number",
        "· Company Website",
        "· Revenue",
        "· Employees",
        "· SIC Code",
        "· Industry Description"
      ],
    },
    whyChooseUs: {
      point:   [
        "• Tailored Solutions: We provide customized OEM part sourcing solutions tailored to your specific defence needs and goals.",
        "• Expertise Across Industries: Our extensive experience in various defence sectors enables us to source parts that meet stringent requirements.",
        "• Dedicated Team: Our skilled sourcing specialists are committed to delivering high-quality, reliable solutions with ongoing support and guidance.",
        "• Cutting-Edge Technologies: We use the latest tools and technologies to streamline sourcing processes and ensure efficiency.",
        "• Focus on Quality: We prioritize quality assurance, ensuring that all sourced parts meet the highest standards and defence specifications.",
        "• Proven Track Record: With a history of successful, on-time, and within-budget project deliveries, you can trust us to meet your defence OEM part sourcing needs with precision and excellence."
      ],
    },
  },
  {
    id: 'parts-for-defence-industry',
    serviceHeader: "Defence Component Sourcing",
    content:
      "Unlock the potential of your defence projects with our defence OEM Part Sourcing services. Our team of experts specializes in sourcing high-quality, reliable OEM parts for defence applications. Whether you're a small contractor, a growing defence startup, or an established defence enterprise, we tailor our solutions to meet your unique needs and drive your projects forward.",
    image:DefenceComponentSourcing,
    keyFeatures: {
      feature: [
        "1. We offer tailored sourcing solutions that meet your specific requirements, ensuring that you get the right parts for your defence applications.",
        "2. Our sourcing process includes rigorous quality checks to ensure that all parts meet the highest industry standards and defence specifications.",
        "3. We source a wide range of OEM parts, including electronic components, mechanical assemblies, and specialized materials, to support various defence applications.",
        "4. We ensure that all sourced parts comply with relevant defence standards and certifications, providing you with peace of mind and reliability.",
        "5. Our extensive global network allows us to source parts from trusted manufacturers and suppliers worldwide, ensuring timely delivery and competitive pricing.",
        "6. We provide inventory management services to help you maintain optimal stock levels, reducing lead times and ensuring readiness for your defence projects.",
        "7. We leverage our industry expertise and supplier relationships to provide cost-effective sourcing solutions, helping you manage your budget without compromising on quality."
    ],
    },
    infrastructure: {
      title:
        "Saintsmen Systems is equipped with a comprehensive infrastructure to support Defence Component Sourcing. Our vast network of certified suppliers and manufacturers allows us to efficiently source top-quality defence components. We leverage advanced inventory management systems and a dedicated logistics team to ensure timely delivery and strict adherence to defence industry standards. Our state-of-the-art facilities are designed to meet the rigorous demands of defence components, guaranteeing precision, reliability, and compliance in every order. Trust Saintsmen Systems for a seamless and efficient sourcing experience for all your defence component needs.",
      points: [],
    },
    techStacks: {
      title: "Sourcing Techniques",
      array: [
        "Agile Methodology: We employ agile sourcing practices for flexibility, adaptability, and timely delivery of your OEM parts.",
        "Continuous Improvement: We constantly refine our sourcing processes to ensure efficiency and effectiveness.",
        "Supplier Audits: Regular supplier audits maintain high standards and identify potential issues early.",
        "Risk Management: We implement robust risk management strategies to mitigate any sourcing challenges."
      ],
    },
    developmentTechniques: {
      title: "Sourcing Cycle",
      array: [
        "1. Requirement Gathering: We collaborate with you to collect requirements, understand your objectives, and define the project scope.",
        "2. Planning: We create a detailed sourcing plan that includes timelines, milestones, and resource allocation.",
        "3. Supplier Identification: Our team identifies and evaluates potential suppliers based on your specific needs.",
        "4. Quality Assurance: We conduct thorough quality checks to ensure all parts meet the required standards.",
        "5. Procurement: We manage the procurement process, ensuring timely and efficient delivery of parts."
      ],
    },
    developmentCycle: {
      title: "Defence Component Sourcing Process",
      array:  [
        "1. Requirement Analysis: Determine specific requirements for defence components.",
        "2. Supplier Identification: Search within our network of certified space component suppliers.",
        "3. Quality Verification: Conduct rigorous quality checks to ensure compliance with defence industry standards.",
        "4. Component Approval: Approve components after thorough vetting for quality and reliability.",
        "5. Logistics Coordination: Manage logistics to ensure timely and secure delivery.",
        "6. Client Communication: Maintain clear and consistent communication throughout the process.",
        "7. Quality Assurance: Ensure meticulous attention to detail for the highest quality defence components."
    ],
    },
    whyChooseUs: {
      point:   [
        "• Tailored Solutions: We provide customized OEM part sourcing solutions tailored to your specific defence needs and goals.",
        "• Expertise Across Industries: Our extensive experience in various defence sectors enables us to source parts that meet stringent requirements.",
        "• Dedicated Team: Our skilled sourcing specialists are committed to delivering high-quality, reliable solutions with ongoing support and guidance.",
        "• Cutting-Edge Technologies: We use the latest tools and technologies to streamline sourcing processes and ensure efficiency.",
        "• Focus on Quality: We prioritize quality assurance, ensuring that all sourced parts meet the highest standards and defence specifications.",
        "• Proven Track Record: With a history of successful, on-time, and within-budget project deliveries, you can trust us to meet your defence OEM part sourcing needs with precision and excellence."
      ],
    },
  },
  {
    id: 'space-grade-component',
    serviceHeader: "Space Component Sourcing",
    content:
      "Unlock the potential of your aerospace projects with our Space OEM Part Sourcing services. Our team of experts specializes in sourcing high-quality, reliable OEM parts for space applications. Whether you're a small aerospace startup, a growing company, or an established enterprise, we tailor our solutions to meet your unique needs and drive your projects forward.",
    image:SpaceComponentSourcing,
    keyFeatures: {
      feature: [
        "1. We provide tailored sourcing solutions that meet the specific requirements of your aerospace projects, ensuring you get the right parts for your needs.",
        "2. Our rigorous quality assurance processes ensure that every part we source meets the highest standards of reliability and performance for space applications.",
        "3. We maintain a comprehensive inventory of OEM parts, enabling quick and efficient sourcing to keep your projects on track.",
        "4. Our extensive network of trusted suppliers allows us to source parts from reputable manufacturers, ensuring authenticity and compliance with industry standards.",
        "5. We offer expert technical support to assist with part selection, integration, and troubleshooting, ensuring seamless incorporation into your projects.",
        "6. Our logistics management services ensure timely and efficient delivery of parts, minimizing downtime and keeping your projects on schedule.",
        "7. We provide complete documentation for all sourced parts, including certification and compliance information, to support your project requirements.",
        "8. Our commitment doesn't end with delivery. We offer ongoing support and follow-up services to address any issues and ensure the continued success of your projects."
    ],
    },
    infrastructure: {
      title:
        "Saintsmen Systems has a robust infrastructure to support Space Component Sourcing. Our extensive network of certified suppliers and manufacturers ensures we can source top-quality space components efficiently. We utilize advanced inventory management systems and a dedicated logistics team to guarantee timely delivery and compliance with space industry standards. Our state-of-the-art facilities are designed to meet the stringent demands of space components, ensuring precision, reliability, and adherence to rigorous specifications. Partner with Saintsmen Systems for a seamless and efficient sourcing experience for all your space component needs.",
      points: [],
    },
    techStacks: {
      title: "Sourcing Techniques",
      array: [
        "Agile Methodology: We employ agile practices for flexibility, adaptability, and timely delivery of your space OEM parts.",
        "Continuous Improvement: We continuously refine our processes to enhance efficiency and ensure the highest quality of service.",
        "Lean Manufacturing: We leverage lean manufacturing principles to minimize waste and maximize value in the sourcing process.",
        "Risk Management: Our proactive risk management strategies identify and mitigate potential issues early, ensuring smooth project execution."
    ],
    },
    developmentTechniques: {
      title: "Sourcing Cycle",
      array: [
        "• Requirement Gathering: We collaborate with you to collect requirements, understand your objectives, and define the project scope.",
        "• Planning: We create a detailed project plan that includes timelines, milestones, and resource allocation.",
        "• Sourcing: Our sourcing team identifies and secures the necessary OEM parts from reputable suppliers.",
        "• Quality Assurance: We conduct thorough quality checks to ensure all parts meet the required standards.",
        "• Logistics: We manage the logistics to ensure timely delivery of parts to your specified location."
    ],
    },
    developmentCycle: {
      title: "Space Component Sourcing Process",
      array:  [
        "1. Requirement Analysis: Determine specific requirements for space components.",
        "2. Supplier Identification: Search within our network of certified space component suppliers.",
        "3. Quality Verification: Conduct rigorous quality checks to ensure compliance with space industry standards.",
        "4. Component Approval: Approve components after thorough vetting for quality and reliability.",
        "5. Logistics Coordination: Manage logistics to ensure timely and secure delivery.",
        "6. Client Communication: Maintain clear and consistent communication throughout the sourcing process.",
        "7. Quality Assurance: Ensure meticulous attention to detail for the highest quality space components."
    ],
    },
    whyChooseUs: {
      point:  [
        "1. Tailored Solutions: We provide customized aircraft OEM part sourcing solutions tailored to your specific needs and goals.",
        "2. Expertise Across Aircraft Models: Our extensive experience with various aircraft models enables us to source parts that meet your exact specifications.",
        "3. Dedicated Team: Our skilled sourcing specialists are committed to delivering high-quality, innovative solutions with ongoing support and guidance.",
        "4. Cutting-Edge Technologies: We use the latest tools and technologies to streamline the sourcing process and ensure timely delivery.",
        "5. Focus on Quality: We prioritize quality assurance, ensuring that all sourced parts meet the highest standards and regulatory requirements.",
        "6. Proven Track Record: With a history of successful, on-time, and within-budget project deliveries, you can trust us to meet your aircraft OEM part sourcing needs with precision and excellence."
    ],
    },
  },
  {
    id: 'marine-grade-component',
    serviceHeader: "Marine Component Sourcing",
    content:
      "Unlock the potential of your marine operations with our Marine OEM Part Sourcing services. Our team of experts specializes in sourcing high-quality, genuine OEM parts for various marine applications. Whether you're a small maritime business, a growing shipyard, or an established fleet operator, we tailor our solutions to meet your unique needs and keep your vessels running smoothly.",
    image:MarineComponentSourcing,
    keyFeatures: {
      feature: [
        "1. We source a wide range of OEM parts, including engines, propulsion systems, electrical components, and more, ensuring that all your marine equipment needs are met.",
        "2. Our stringent quality control processes guarantee that every part we source meets the highest standards of reliability and performance.",
        "3. Leveraging our extensive global network of trusted suppliers, we ensure timely and cost-effective procurement of parts from leading manufacturers.",
        "4. We provide customized sourcing solutions tailored to your specific requirements, helping you maintain operational efficiency and reduce downtime.",
        "5. Our inventory management services help you keep track of your parts, ensuring you always have the critical components you need when you need them.",
        "6. Our team offers technical support and expertise, assisting you with part selection, compatibility, and installation to ensure optimal performance.",
        "7. We ensure that all sourced parts comply with industry regulations and standards, giving you peace of mind regarding safety and legal compliance.",
        "8. We handle all aspects of logistics, from procurement to delivery, ensuring your parts arrive on time and in excellent condition."
    ],
    },
    infrastructure: {
      title:
        "Saintsmen Systems has a robust infrastructure to support Marine Component Sourcing. Our extensive network of certified suppliers and manufacturers ensures we source top-quality marine components efficiently. We use advanced inventory management systems and a dedicated logistics team to guarantee timely delivery and adherence to maritime industry standards. Our state-of-the-art facilities are equipped to handle the specific demands of marine machinery components, ensuring precision and reliability in every order. Partner with Saintsmen Systems for a seamless and efficient sourcing experience for all your marine machinery needs.",
      points: [],
    },
    techStacks: {
      title: "Sourcing Techniques",
      array: [
        "Market Analysis: We continuously analyze the market to identify the best suppliers and pricing for marine OEM parts.",
        "Supplier Audits: Regular audits of our suppliers ensure adherence to quality and performance standards.",
        "Negotiation: Our experienced team negotiates the best terms and prices, passing the savings on to you.",
        "Documentation: Comprehensive documentation and certification for all sourced parts ensure traceability and authenticity."
    ],
    },
    developmentTechniques: {
      title: "Sourcing Cycle",
      array: [
        "1. Requirement Gathering: We collaborate with you to collect requirements, understand your needs, and define the sourcing scope.",
        "2. Planning: We create a detailed sourcing plan that includes timelines, milestones, and resource allocation.",
        "3. Supplier Identification: We identify and evaluate potential suppliers based on your specific requirements.",
        "4. Procurement: Our procurement team secures the parts, ensuring compliance with quality and delivery standards.",
        "5. Quality Assurance: We conduct thorough inspections and tests to ensure all parts meet the highest quality standards."
    ],
    },
    developmentCycle: {
      title: "Marine Component Sourcing Process",
      array: [
        "1. Requirement Assessment: Identify specific needs and requirements for marine components.",
        "2. Supplier Identification: Search within our network of certified marine component suppliers.",
        "3. Quality Inspection: Perform rigorous quality checks to ensure compliance with maritime standards.",
        "4. Approval: Approve components after thorough vetting for quality and reliability.",
        "5. Logistics Management: Coordinate logistics to ensure timely and accurate delivery.",
        "6. Client Communication: Maintain clear and consistent communication throughout the process.",
        "7. Quality Assurance: Ensure meticulous attention to detail for the highest quality marine components."
    ],
    },
    whyChooseUs: {
      point:  [
        "1. Tailored Solutions: We provide customized aircraft OEM part sourcing solutions tailored to your specific needs and goals.",
        "2. Expertise Across Aircraft Models: Our extensive experience with various aircraft models enables us to source parts that meet your exact specifications.",
        "3. Dedicated Team: Our skilled sourcing specialists are committed to delivering high-quality, innovative solutions with ongoing support and guidance.",
        "4. Cutting-Edge Technologies: We use the latest tools and technologies to streamline the sourcing process and ensure timely delivery.",
        "5. Focus on Quality: We prioritize quality assurance, ensuring that all sourced parts meet the highest standards and regulatory requirements.",
        "6. Proven Track Record: With a history of successful, on-time, and within-budget project deliveries, you can trust us to meet your aircraft OEM part sourcing needs with precision and excellence."
    ],
    },
  },
  {
    id: 'industrial-machinery-spares',
    serviceHeader: "Industrial Machinery Component Sourcing",
    content:
      "Unlock the potential of your operations with our Industrial Machinery OEM Parts Sourcing services. Our team of experts specializes in sourcing high-quality, reliable OEM parts for a wide range of industrial machinery. Whether you're a small business, a growing startup, or an established enterprise, we tailor our solutions to meet your unique needs and drive your business forward.",
    image:IndustrialComponentSourcing,
    keyFeatures: {
      feature: [
        "1. We provide tailored OEM parts sourcing solutions that reflect your specific requirements and ensure the highest quality standards.",
        "2. Our sourcing capabilities cover a broad spectrum of industrial machinery parts, ensuring that we can meet all your needs.",
        "3. We implement rigorous quality assurance processes to ensure that all sourced parts meet the highest industry standards.",
        "4. Our sourcing strategies focus on delivering cost-effective solutions without compromising on quality or performance.",
        "5. We have established a robust network of trusted suppliers, enabling us to source parts quickly and efficiently.",
        "6. We ensure that all sourced parts come with the necessary documentation and comply with relevant industry standards and regulations.",
        "7. Our sourcing services are scalable, ensuring that we can meet your growing needs as your business expands."
    ],
    },
    infrastructure: {
      title:
        "Saintsmen Systems is equipped with a robust infrastructure to support Industrial Machinery Component Sourcing. Our extensive network of trusted suppliers and manufacturers allows us to source top-quality components efficiently. We utilize advanced inventory management systems and a dedicated logistics team to ensure timely delivery and adherence to industry standards. Our state-of-the-art facilities are designed to handle the specific demands of industrial machinery components, guaranteeing precision and reliability in every order. Partner with Saintsmen Systems for a seamless and efficient sourcing experience.",
      points: [],
    },
    techStacks: {
      title: "Sourcing Techniques",
      array: [
        "Agile Methodology: We employ agile sourcing practices for flexibility, adaptability, and timely delivery of OEM parts.",
        "Continuous Improvement: We continuously improve our sourcing processes to ensure rapid and reliable delivery of high-quality parts.",
        "Quality Control: We emphasize quality through stringent quality control practices, minimizing errors and ensuring robust parts.",
        "Supplier Audits: Regular supplier audits maintain high standards and identify potential issues early."
    ],
    },
    developmentTechniques: {
      title: "Sourcing Cycle",
      array: [
        "• Requirement Gathering: We collaborate with you to collect requirements, understand your objectives, and define the project scope.",
        "• Planning: We create a detailed sourcing plan that includes timelines, milestones, and resource allocation.",
        "• Sourcing: Our sourcing team identifies and procures the required OEM parts, following best practices and industry standards.",
        "• Quality Assurance: We conduct thorough quality checks to ensure all parts meet the necessary standards and specifications.",
        "• Delivery: We manage the logistics to ensure timely delivery of parts to your specified location."
    ],
    },
    developmentCycle: {
      title: "Industrial Machinery Component Sourcing Process",
      array: [
        "1. Needs Assessment: Determine specific requirements for machinery components.",
        "2. Supplier Identification: Search within our network of trusted suppliers and manufacturers.",
        "3. Quality Verification: Conduct rigorous quality checks and ensure compliance with industry standards.",
        "4. Component Approval: Approve components after thorough vetting.",
        "5. Logistics Coordination: Manage logistics to ensure timely and accurate delivery.",
        "6. Clear Communication: Maintain clear communication with clients throughout the sourcing process.",
        "7. Quality Assurance: Ensure meticulous attention to detail for the highest quality components."
    ],
    },
    whyChooseUs: {
      point:  [
        "1. Tailored Solutions: We provide customized aircraft OEM part sourcing solutions tailored to your specific needs and goals.",
        "2. Expertise Across Aircraft Models: Our extensive experience with various aircraft models enables us to source parts that meet your exact specifications.",
        "3. Dedicated Team: Our skilled sourcing specialists are committed to delivering high-quality, innovative solutions with ongoing support and guidance.",
        "4. Cutting-Edge Technologies: We use the latest tools and technologies to streamline the sourcing process and ensure timely delivery.",
        "5. Focus on Quality: We prioritize quality assurance, ensuring that all sourced parts meet the highest standards and regulatory requirements.",
        "6. Proven Track Record: With a history of successful, on-time, and within-budget project deliveries, you can trust us to meet your aircraft OEM part sourcing needs with precision and excellence."
    ],
    },
  },
  {
    id: 'aircraft-component',
    serviceHeader: "Aircraft Component Sourcing",
    content:
      "Unlock the potential of your aviation operations with our Aircraft OEM Part Sourcing services. Our team of experts specializes in sourcing high-quality, original equipment manufacturer (OEM) parts for various aircraft. Whether you're a small airline, a growing aviation startup, or an established enterprise, we tailor our solutions to meet your unique needs and ensure your aircraft's optimal performance.",
    image:AirCraftComponentSourcing,
    keyFeatures: {
      feature: [
        "• We provide access to an extensive inventory of OEM parts, ensuring that you can find the exact components you need for your aircraft.",
        "• Our sourcing process includes rigorous quality checks to guarantee that all parts meet the highest industry standards and regulatory requirements.",
        "• We offer competitive pricing on all OEM parts, helping you manage your maintenance costs effectively without compromising on quality.",
        "• Our efficient logistics network ensures prompt delivery of parts, minimizing downtime and keeping your aircraft in the air.",
        "• Our team of technical experts is available to provide support and guidance, ensuring the correct installation and optimal performance of sourced parts.",
        "• We ensure all sourced parts comply with industry regulations and standards, providing peace of mind and maintaining the airworthiness of your aircraft."
    ],
    },
    infrastructure: {
      title:
        "Saintsmen Systems has a strong infrastructure for Aircraft Component Sourcing, featuring a network of certified suppliers and advanced inventory management. Our dedicated logistics team ensures timely delivery and industry compliance. Our facilities are designed for precision and reliability, providing seamless and efficient sourcing for your aircraft component needs.",
      points: [],
    },
    techStacks: {
      title: "Sourcing Techniques",
      array: [
        "We leverage a global network of trusted suppliers to source the highest quality OEM parts.",
        "Our logistics strategies ensure rapid and reliable delivery of parts to your location.",
        "Stringent quality control measures are in place to verify the authenticity and condition of all parts.",
        "Regular audits of our suppliers help maintain high standards and identify any potential issues early."
    ],
    },
    developmentTechniques: {
      title: "Sourcing Cycle",
      array: [
        "• Requirement Gathering: We collaborate with you to collect requirements, understand your objectives, and define the project scope.",
        "• Planning: We create a detailed sourcing plan that includes timelines, milestones, and resource allocation.",
        "• Sourcing: Our sourcing team identifies and procures the required OEM parts, following best practices and industry standards.",
        "• Quality Assurance: We conduct thorough quality checks to ensure all parts meet the necessary standards and specifications.",
        "• Delivery: We manage the logistics to ensure timely delivery of parts to your specified location."
    ],
    },
    developmentCycle: {
      title: "Aircraft Component Sourcing Process",
      array: [
        "1. Requirement Analysis: Identify specific needs and requirements.",
        "2. Supplier Search: Conduct a thorough search within our network of certified suppliers.",
        "3. Quality Vetting: Rigorously vet each component for quality and compliance with industry standards.",
        "4. Approval: Approve components based on stringent quality checks.",
        "5. Logistics Management: Manage logistics to ensure timely delivery.",
        "6. Clear Communication: Maintain clear and consistent communication throughout the process.",
        "7. Quality Assurance: Ensure meticulous attention to detail for the highest quality components."
    ],
    },
    whyChooseUs: {
      point:  [
        "1. Tailored Solutions: We provide customized aircraft OEM part sourcing solutions tailored to your specific needs and goals.",
        "2. Expertise Across Aircraft Models: Our extensive experience with various aircraft models enables us to source parts that meet your exact specifications.",
        "3. Dedicated Team: Our skilled sourcing specialists are committed to delivering high-quality, innovative solutions with ongoing support and guidance.",
        "4. Cutting-Edge Technologies: We use the latest tools and technologies to streamline the sourcing process and ensure timely delivery.",
        "5. Focus on Quality: We prioritize quality assurance, ensuring that all sourced parts meet the highest standards and regulatory requirements.",
        "6. Proven Track Record: With a history of successful, on-time, and within-budget project deliveries, you can trust us to meet your aircraft OEM part sourcing needs with precision and excellence."
    ],
    },
  },
  {
    id: 'custom-software-development',
    serviceHeader: "Custom Software Development",
    content:
      "Custom Software Development involves creating tailored software solutions to meet specific business needs. Our team of experienced developers works closely with clients to understand their requirements and deliver high-quality, scalable software solutions.",
    image: CustomSoftwareDevelopment,
    keyFeatures: {
      feature: [
        "1. Tailored Solutions: We design and develop software from scratch, tailored to meet your unique business requirements.",
        "2. Scalability: Our solutions are built to scale with your business, ensuring seamless performance as you grow.",
        "3. Flexibility: We prioritize flexibility, allowing for easy integration with existing systems and future upgrades.",
        "4. Security: We implement robust security measures to safeguard your data and protect against potential threats.",
        "5. User Experience: Our focus on user-centric design ensures intuitive interfaces and seamless user experiences.",
        "6. Maintenance and Support: We provide ongoing maintenance and support services to ensure your software operates smoothly.",
      ],
    },
    infrastructure: {
      title:
        "Our infrastructure is built on reliable and scalable cloud platforms, ensuring optimal performance and flexibility for your custom software solutions.",
      points: [],
    },
    techStacks: {
      title: "Tech Stack",
      array: [
        "Frontend: HTML5, CSS3, JavaScript (React.js, AngularJS, Vue.js)",
        "Backend: Node.js, Python (Django, Flask), Ruby (Ruby on Rails), Java (Spring Boot)",
        "Database: MySQL, PostgreSQL, MongoDB, Firebase",
        "Design: Adobe XD, Figma, Sketch",
        "Advanced Technologies: AI/ML integration, Blockchain, IoT, AR/VR",
        "Servers: Apache, Nginx",
        "DevOps: Docker, Kubernetes, Jenkins, Git, AWS (Amazon Web Services), Azure (Microsoft Azure), Google Cloud Platform",
      ],
    },
    developmentTechniques: {
      title: "Development Techniques",
      array: [
        "1. Agile Methodology: We follow agile development practices to ensure flexibility and adaptability throughout the development process.",
        "2. Continuous Integration/Continuous Deployment (CI/CD): We automate the deployment process to streamline development and ensure rapid delivery of updates.",
        "3. Test-Driven Development (TDD): We employ TDD practices to ensure code quality and minimize errors.",
        "4. Code Reviews: Regular code reviews are conducted to maintain code quality and identify potential issues early on.",
      ],
    },
    developmentCycle: {
      title: "Development Cycle",
      array: [
        "1. Requirement Gathering: We work closely with clients to gather requirements and define project goals.",
        "2. Planning: A detailed project plan is created, outlining timelines, milestones, and resource allocation.",
        "3. Design: Our design team creates wireframes and prototypes based on client feedback.",
        "4. Development: The development team starts building the software solution, following best practices and coding standards.",
        "5. Testing: Rigorous testing is conducted to identify and fix bugs, ensuring the software meets quality standards.",
        "6. Deployment: The software is deployed to production environments following a controlled rollout strategy.",
        "7. Maintenance and Support: We provide ongoing maintenance and support services to address any issues and ensure the software operates smoothly.",
      ],
    },
    whyChooseUs: {
      point: [
        "1. Tailored Solutions: We craft custom software tailored specifically to your unique business needs, ensuring optimal functionality and efficiency.",
        "2. Expertise Across Industries: With experience spanning various industries, we understand the intricacies of different sectors and can develop software solutions that resonate with your specific industry requirements.",
        "3. Dedicated Team: Our team of skilled developers is dedicated to delivering high-quality, innovative solutions, providing you with ongoing support and guidance throughout the development process.",
        "4. Cutting-Edge Technologies: We leverage the latest technologies and development frameworks to build scalable, future-proof software that keeps your business ahead of the curve.",
        "5. Seamless Integration: Our solutions seamlessly integrate with your existing systems and workflows, minimizing disruption and maximizing productivity.",
      ],
    },
  },
  {
    id:"unlock-ai-driven-transformation",
    serviceHeader:"Unlock AI Driven Transformation",
    content:"Unlock AI-Driven Transformation by leveraging advanced artificial intelligence technologies to optimize your business processes. Our expert team specializes in integrating and fine-tuning AI models to streamline workflows and maximize operational efficiency.",
    image:UnlockAI,
    keyFeatures:{
      feature:[
        "1. Advanced AI Integration: We design and implement AI solutions tailored to meet your unique business needs, ensuring seamless integration with your existing systems.",
        "2. Enhanced Efficiency: Our AI-driven solutions automate repetitive tasks, reduce manual effort, and improve overall efficiency across your organization.",
        "3. Scalability: Our AI solutions are built to scale with your business, providing reliable performance as your needs evolve.",
        "4. Flexibility: We prioritize flexible solutions that can be easily adapted to changing business requirements and future technological advancements.",
        "5. Security: We implement robust security measures to protect your data and ensure compliance with industry standards.",
        "6. User Experience: Our focus on user-centric design ensures intuitive interfaces and seamless user experiences.",
        "7. Maintenance and Support: We provide ongoing maintenance and support services to keep your AI solutions running smoothly and efficiently."
      ]
    },
    infrastructure: {
      title:
        "Our infrastructure is built on reliable and scalable cloud platforms, ensuring optimal performance and flexibility for your AI-driven solutions.",
      points: [],
    },
    techStacks: {
      title: "Tech Stacks",
      array: [
  "· AI/ML Frameworks: TensorFlow · PyTorch · scikit-learn",
  "· Programming Languages: Python · R · Java",
  "· Database: MySQL · PostgreSQL · MongoDB · BigQuery",
  "· Design: Adobe XD · Figma · Sketch",
  "· Advanced Technologies: Natural Language Processing (NLP) · Computer Vision · Predictive Analytics",
  "· DevOps: Docker · Kubernetes · Jenkins · AWS (Amazon Web Services) · Google Cloud Platform"
],
    },
    developmentTechniques: {
      title: "Development Techniques",
      array: [
        "· Agile Methodology: We follow agile development practices to ensure flexibility and adaptability throughout the development process.",
        "· Continuous Integration/Continuous Deployment (CI/CD): We automate the deployment process to streamline development and ensure rapid delivery of updates.",
        "· Test-Driven Development (TDD): We employ TDD practices to ensure code quality and minimize errors.",
        "· Code Reviews: Regular code reviews are conducted to maintain code quality and identify potential issues early on."
      ],
    },
    developmentCycle: {
      title: "Development Cycle",
      array: [
        "· Requirement Gathering: We collaborate with clients to gather requirements and establish clear project goals.",
        "· Planning: We create a comprehensive project plan, outlining timelines, milestones, and resource allocation.",
        "· Design: Our design team creates wireframes and prototypes, refining them based on client feedback.",
        "· Development: The development team starts building AI conversational tools, following best practices and coding standards.",
        "· Testing: We conduct thorough testing to identify and resolve bugs, ensuring the tools meet high-quality standards.",
        "· Deployment: The tools are deployed to production environments using a carefully planned rollout strategy.",
        "· Maintenance and Support: We offer continuous maintenance and support services to promptly address any issues and ensure seamless tool operation."
      ],
    },
    whyChooseUs: {
      point: [
        "· Customized Solutions: Our team specializes in creating AI-driven solutions that are tailored to meet your specific business requirements, guaranteeing efficiency and optimal performance.",
        "· Industry Expertise: With extensive experience across various sectors, we possess deep insights into industry nuances, enabling us to develop AI solutions that align seamlessly with your industry-specific needs.",
        "· Dedicated Team of Experts: Our skilled developers are committed to delivering innovative, high-quality solutions. We offer continuous support and guidance throughout the development lifecycle.",
        "· Cutting-Edge AI Technologies: We harness the latest advancements in AI technologies and frameworks to develop scalable solutions that future-proof your business and maintain a competitive edge.",
        "· User-Centric Approach: We place a strong emphasis on designing user experiences that are intuitive and seamless, enhancing user satisfaction and productivity.",
        "· Proven Success: With a solid track record of delivering projects on time and within budget, you can rely on us to realize your AI vision with precision and excellence."
      ],
    },
  

  },
  {
    id:"industry-specific-lists",
    serviceHeader:"Industry Specific Lists",
    content:"Our Industry Specific Lists provide unique sector classifications to help you target the industries you are most interested in. Designed to meet your business objectives, our Industry Mailing List Service offers detailed contact information for important prospects across various sectors.",
    image:IndustrySpecificLists,
    keyFeatures:{
      feature:[
        "• Sector Classifications: We offer unique and precise sector classifications to ensure you reach the right industries effectively.",
        "• Comprehensive Contact Information: Our lists include detailed contact information, making it easier to connect with key prospects.",
        "• Wide Range of Industries: We cover a broad spectrum of industries, including Healthcare, Banking and Finance, Hospitality, Insurance, Travel, Media, Real Estate, Automotive, Information Technology (IT), Manufacturing, Retail, Biotechnology, Telecommunication, Logistics, and Mining.",
        "• Accurate and Updated Records: Our records are continually updated to maintain accuracy and relevance, helping you achieve better results.",
        "• Flexible and Scalable Solutions: Our lists and services are flexible and scalable, designed to grow with your business needs.",
        "• Secure Data Handling: We prioritize data security and ensure compliance with industry standards to protect your information."
      ],
      
    },
    infrastructure: {
      title:
        "Our infrastructure is built on reliable and scalable cloud platforms, ensuring optimal performance and flexibility for managing and utilizing data appending services.",
      points: [
      ],
    },
    techStacks: {
      title: "Industry Specific Lists Process",
      array: [
        "Requirement Gathering & Data Collection: We collaborate with clients to gather requirements and source data from verified databases, ensuring high accuracy.",
        "Data Verification & Segmentation: Our team rigorously checks data authenticity and segments it based on criteria to create targeted lists.",
        "List Compilation & Quality Assurance: Segmented data is compiled into comprehensive industry-specific lists and undergoes thorough quality checks for accuracy.",
        "Delivery & Updates: The final lists are securely delivered, with regular updates to maintain their accuracy and effectiveness."
      ],
    },
    developmentTechniques: {
      title: "Listing Techniques",
      array: [
        "Data Mining: Use advanced data mining techniques to extract relevant information from large datasets.",
        "Web Scraping: Implement web scraping tools to gather data from various online sources and websites.",
        "Database Integration: Integrate various databases to compile comprehensive and detailed industry-specific lists.",
        "Manual Research: Conduct manual research to verify and enhance the accuracy of the data.",
        "Survey and Feedback: Collect data through surveys and feedback forms to ensure updated and relevant contact information."
      ],
    },
    developmentCycle: {
      title: "Selection Criteria",
      array: [
        "Our Technology Lists can be customized based on various criteria to suit your specific business needs",
        "· Company Name",
        "· Contact Name",
        "· Job Title",
        "· Mailing Address",
        "· City",
        "· State",
        "· ZIP Code",
        "· Phone & Fax Number",
        "· Company Website",
        "· Revenue",
        "· Employees",
        "· SIC Code",
        "· Industry Description"
      ]
      
       ,
    },
    whyChooseUs: {
      point:  [
        "• Comprehensive and Accurate Lists: We deliver detailed and precise contact lists, ensuring you effectively reach the right people.",
        "• Expertise Across Industries: Our extensive experience across various industries allows us to understand sector-specific intricacies and provide contact lists that align with your industry requirements.",
        "• Dedicated Team: Our team of skilled professionals is committed to delivering high-quality solutions, offering continuous support and guidance throughout the process.",
        "• Cutting-Edge Technologies: We utilize the latest technologies and frameworks to develop scalable, future-proof solutions that keep your business ahead of the curve.",
        "• Focus on Data Security: We implement robust security measures to protect your data and ensure compliance with industry standards.",
        "• Proven Track Record: With a history of delivering successful projects on time and within budget, you can rely on us to bring your contact appending vision to life with precision and excellence."
      ],
    },
  

  },
  {
    id:"contact-appending",
    serviceHeader:"Contact Appending",
    content:"Contact Appending is a crucial part of your marketing strategy, allowing you to gain comprehensive contact lists featuring the best people to contact with their current titles. Our service also provides multiple contacts of key decision-makers within a company. We keep track of changing titles and job roles, ensuring you have up-to-date contact information for various positions within a company.",
    image:ContactAppending,
    keyFeatures:{
      feature:[
        "• Comprehensive Contact Lists: We provide detailed contact lists, ensuring you reach the right people with the most current titles.",
        "• Multiple Key Contacts: Our service includes multiple contacts of key decision-makers within a company, enhancing your outreach efforts.",
        "• Up-to-Date Information: We maintain current information about contact details, titles, and job roles, ensuring your database is always accurate.",
        "• Enhanced Marketing Efforts: By appending accurate contact information, we help improve the effectiveness of your marketing campaigns.",
        "• Flexible and Scalable Solutions: Our contact appending services are flexible and scalable to meet the evolving needs of your business.",
        "• Secure Data Handling: We prioritize data security and ensure compliance with industry standards to protect your information."
      ],
      
    },
    infrastructure: {
      title:
        "Our infrastructure is built on reliable and scalable cloud platforms, ensuring optimal performance and flexibility for managing and utilizing data appending services.",
      points: [
      ],
    },
    techStacks: {
      title: "Contact Appending Process",
      array: [
        "Data Collection: Gather the existing contact database from clients.",
        "Validation: Validate the contact information to identify outdated or incorrect entries.",
        "Appending: Append current and accurate contact details, including titles and job roles.",
        "Quality Check: Conduct thorough quality checks to ensure the accuracy of the updated database.",
        "Delivery: Provide the updated contact database to clients for their marketing and outreach efforts."
      ],
    },
    developmentTechniques: {
      title: "Appending Techniques",
      array: [
        "Data Matching: We match your existing data with our master database using advanced algorithms to ensure high accuracy and relevance.",
        "Data Standardization: We standardize the data format to maintain consistency and ease of use.",
        "Data Validation: Validate the appended data to ensure it meets quality standards and is free from errors.",
        "Regular Updates: Continuously update the master database to incorporate new information and maintain data accuracy."
      ],
    },
    developmentCycle: {
      title: "Appending Cycle",
      array: [
        "• Requirement Gathering: Collaborate with clients to understand their data needs and objectives.",
        "• Planning: Develop a detailed plan outlining the data appending process, timelines, and resource allocation.",
        "• Data Preparation: Clean and format the client's existing data for comparison and appending.",
        "• Appending Execution: Conduct the data matching, standardization, and enrichment processes.",
        "• Quality Assurance: Perform rigorous quality checks to ensure the accuracy and reliability of the appended data.",
        "• Feedback and Revision: Collect client feedback and make any necessary adjustments to the appended data.",
        "• Final Delivery: Deliver the enriched and validated data to the client.",
        "• Ongoing Support: Provide continuous support and updates to ensure data accuracy over time."
      ] ,
    },
    whyChooseUs: {
      point:  [
        "• Comprehensive and Accurate Lists: We deliver detailed and precise contact lists, ensuring you effectively reach the right people.",
        "• Expertise Across Industries: Our extensive experience across various industries allows us to understand sector-specific intricacies and provide contact lists that align with your industry requirements.",
        "• Dedicated Team: Our team of skilled professionals is committed to delivering high-quality solutions, offering continuous support and guidance throughout the process.",
        "• Cutting-Edge Technologies: We utilize the latest technologies and frameworks to develop scalable, future-proof solutions that keep your business ahead of the curve.",
        "• Focus on Data Security: We implement robust security measures to protect your data and ensure compliance with industry standards.",
        "• Proven Track Record: With a history of delivering successful projects on time and within budget, you can rely on us to bring your contact appending vision to life with precision and excellence."
      ],
    },
  

  },
  {
    id:"data-appending",
    serviceHeader:"Data Appending",
    content:"Our Data Appending services help you generate business email leads from your existing data by comparing it with our master database, which contains complete contact details of prospects. With years of experience and high expertise, we provide end-to-end database solutions to satisfied clients globally.",
    image:DataAppending,
    keyFeatures:{
      feature:[
        "• Lead Generation: We generate business email leads from your available data, enhancing your marketing and outreach efforts.",
        "• Comprehensive Master Database: Our master database contains complete and up-to-date contact details of prospects, ensuring high-quality appended data.",
        "• Accuracy and Precision: We ensure high accuracy and precision in data appending, helping you reach the right audience effectively.",
        "• Global Reach: Our services cater to clients globally, providing reliable data solutions for diverse markets.",
        "• Flexible and Scalable Solutions: Our data appending services are flexible and scalable, designed to grow with your business needs.",
        "• Secure Data Handling: We prioritize data security and ensure compliance with industry standards to protect your information."
      ],
      
    },
    infrastructure: {
      title:
        "Our infrastructure is built on reliable and scalable cloud platforms, ensuring optimal performance and flexibility for managing and utilizing data appending services.",
      points: [
      ],
    },
    techStacks: {
      title: "Data Appending Process",
      array: [
        "Data Collection: Gather existing data from clients.",
        "Data Comparison: Compare the client's data with our master database.",
        "Data Enrichment: Append missing contact details and update existing ones.",
        "Quality Check: Conduct thorough quality checks to ensure accuracy.",
        "Delivery: Provide the enriched data to clients for their marketing and outreach efforts."
      ],
    },
    developmentTechniques: {
      title: "Appending Techniques",
      array: [
        "Data Matching: We match your existing data with our master database using advanced algorithms to ensure high accuracy and relevance.",
        "Data Standardization: We standardize the data format to maintain consistency and ease of use.",
        "Data Validation: Validate the appended data to ensure it meets quality standards and is free from errors.",
        "Regular Updates: Continuously update the master database to incorporate new information and maintain data accuracy."
      ],
    },
    developmentCycle: {
      title: "Appending Cycle",
      array: [
        "• Requirement Gathering: Collaborate with clients to understand their data needs and objectives.",
        "• Planning: Develop a detailed plan outlining the data appending process, timelines, and resource allocation.",
        "• Data Preparation: Clean and format the client's existing data for comparison and appending.",
        "• Appending Execution: Conduct the data matching, standardization, and enrichment processes.",
        "• Quality Assurance: Perform rigorous quality checks to ensure the accuracy and reliability of the appended data.",
        "• Feedback and Revision: Collect client feedback and make any necessary adjustments to the appended data.",
        "• Final Delivery: Deliver the enriched and validated data to the client.",
        "• Ongoing Support: Provide continuous support and updates to ensure data accuracy over time."
      ] ,
    },
    whyChooseUs: {
      point:  [
        "• High Expertise: With years of experience and exceptional expertise in data appending, we ensure the highest quality results.",
        "• Comprehensive Master Database: Our extensive and continually updated master database provides the most accurate and relevant contact details.",
        "• Dedicated Team: Our team of skilled professionals is committed to delivering high-quality solutions, offering continuous support and guidance throughout the process.",
        "• Cutting-Edge Technologies: We utilize the latest technologies and frameworks to create scalable, future-proof solutions that keep your business ahead of the curve.",
        "• Focus on Data Security: We implement robust security measures to protect your data and ensure compliance with industry standards.",
        "• Proven Track Record: With a history of delivering successful projects on time and within budget, you can rely on us to bring your data appending vision to life with precision and excellence."
      ],
    },
  

  },
  {
    id:"lead-generation",
    serviceHeader:"Lead Generation",
    content:" Lead generation is the marketing process that creates interest among your target audience about the products or services your business offers, generating potential sales leads. Our Lead Generation Professionals help businesses build trust with key customers through dedicated, consistent, and meaningful communication, ensuring they are ready to become your clients when the decision-making process starts.",
    image:LeadGeneration,
    keyFeatures:{
      feature:[
        "· High-Quality Leads: We specialize in generating high-quality B2B sales leads that turn into sales.",
        "· Targeted Outreach: Our strategies are designed to reach and engage your target audience effectively.",
        "· Building Trust: We help build trust with your key customers through consistent and meaningful communication.",
        "· Proven Strategies: We use proven methods to generate inquiries and convert them into qualified leads.",
        "· Budget-Friendly: Our lead generation services are designed to maximize the number of qualified leads while staying within your budget.",
        "· Performance Tracking: We monitor and analyze the performance of our lead generation efforts to ensure optimal results."
      ],
      
    },
    infrastructure: {
      title:
        "Our infrastructure is built on reliable and scalable cloud platforms, ensuring optimal performance and flexibility for managing and executing email marketing campaigns.",
      points: [
      ],
    },
    techStacks: {
      title: "Lead Generation Process",
      array: [
        "Email List Creation: Develop comprehensive email lists tailored to your target audience.",
        "Campaign Design: Create compelling promotional campaigns and ad content.",
        "Direct Messaging: Craft personalized direct messages to engage your audience.",
        "Distribution: Efficiently distribute emails and ads to your target audience.",
        "Performance Tracking: Monitor and analyze campaign performance to optimize results.",
        "Maintenance: Provide ongoing support and maintenance to ensure continuous improvement."
      ],
    },
    developmentTechniques: {
      title: "Email Marketing Techniques",
      array: [
        "A/B Testing: We employ A/B testing to optimize content and improve lead engagement rates.",
        "Segmentation: Segment leads based on behavior and demographics to deliver more relevant content.",
        "Personalization: Personalize communication to increase engagement and conversion rates.",
        "Analytics and Reporting: Regular analysis and reporting to measure campaign effectiveness and make data-driven decisions.",
        "Automation: Use marketing automation tools to streamline workflows and ensure timely follow-up."
      ],
    },
    developmentCycle: {
      title: "Lead Generation Cycle",
      array:[
        "• Requirement Gathering: We work closely with clients to gather requirements and define lead generation goals.",
        "• Planning: A detailed plan is created, outlining timelines, milestones, and resource allocation.",
        "• Design: Our design team creates visually appealing content and materials based on client feedback.",
        "• Development: The development team starts building the lead generation campaigns, following best practices and industry standards.",
        "• Testing: Rigorous testing is conducted to ensure lead quality and engagement.",
        "• Deployment: The campaigns are launched following a controlled rollout strategy.",
        "• Maintenance and Support: We provide ongoing maintenance and support services to address any issues and ensure the campaigns operate smoothly."
      ] ,
    },
    whyChooseUs: {
      point: [
        "• Specialized Expertise: We excel in generating high-quality B2B sales leads, ensuring optimal results.",
        "• Targeted Strategies: Our lead generation tactics are customized to meet your business needs and target audience.",
        "• Dedicated Team: Our skilled professionals are committed to delivering top-notch solutions, offering continuous support and guidance.",
        "• Proven Methods: We utilize effective methods to generate and qualify leads, ensuring they align with your business.",
        "• Cost-Effective Solutions: Our services are designed to maximize qualified leads while staying within your budget.",
        "• Proven Track Record: With a history of successful lead generation campaigns delivered on time and within budget, you can rely on us to achieve your lead generation goals with precision and excellence."
      ],
    },
  

  },
  {
    id:"digital-marketing-and-branding",
    serviceHeader:"Digital Marketing and Branding",
    content:"In today's digital age, establishing a strong brand presence and effectively marketing it online is crucial for businesses to thrive. Our Digital Branding and Marketing service is designed to help businesses enhance their online visibility, connect with their target audience, and drive meaningful engagement and conversions.",
    image:DigitalMarketingBranding,
    keyFeatures:{
      feature:[
        "· Brand Strategy Development: We develop a tailored brand strategy to define your unique value proposition and positioning in the digital landscape, backed by a comprehensive analysis of market trends, competitor strategies, and audience insights.",
        "· Brand Identity Design: We create a compelling brand identity, including logo design, color palette selection, typography, and brand guidelines, ensuring consistency across all digital platforms to reinforce brand recognition and recall.",
        "· Website Development and Optimization: We provide custom website design and development aligned with your brand identity and business goals, along with the implementation of SEO best practices to improve search engine visibility and drive organic traffic.",
        "· Content Creation and Marketing: We develop engaging content tailored to your target audience and brand voice, including blog posts, social media content, videos, and infographics, and distribute it strategically across relevant digital channels to maximize reach and engagement.",
        "· Social Media Management: We create and manage social media profiles on platforms such as Facebook, Instagram, Twitter, LinkedIn, and more, with regular posting, community engagement, and performance tracking to build brand awareness and foster customer relationships.",
        "· Paid Advertising Campaigns: We strategically plan and execute targeted advertising campaigns across various digital channels, including Google Ads, social media ads, and display advertising, with continuous optimization based on performance metrics to maximize ROI and achieve campaign objectives.",
        "· Analytics and Reporting: We implement advanced analytics tools to track key performance indicators (KPIs) and measure the effectiveness of digital marketing efforts, providing detailed reporting and insights to inform data-driven decision-making and refine marketing strategies."
      ],
      
    },
    infrastructure: {
      title:
        "",
      points: [
        "· Advanced Marketing Tools: We utilize cutting-edge digital marketing tools and technologies to streamline workflows, automate processes, and deliver superior results.",
        "· Secure Data Management: We implement robust data security measures to safeguard sensitive information and ensure compliance with data protection regulations.",
        "· Scalable Infrastructure: We provide flexible and scalable infrastructure to accommodate the evolving needs of your business and support future growth and expansion."
      ],
    },
    techStacks: {
      title: "How It Works",
      array: [
        " We begin with an in-depth consultation to understand your business goals, target audience, and current digital presence.",
        " We analyze market trends, competitor strategies, and audience insights to develop a tailored brand strategy.",
        " Our team designs a compelling brand identity, including logo, color palette, typography, and brand guidelines.",
        " We create a custom website aligned with your brand identity and optimize it for search engines to drive organic traffic.",
        " We develop engaging content tailored to your audience and strategically distribute it across digital channels."
      ],
    },
    developmentTechniques: {
      title: "How it Works",
      array: [
        "· We manage your social media profiles, post regularly, engage with the community, and track performance.",
        "· We plan and execute targeted advertising campaigns and continuously optimize them to maximize ROI.",
        "· We implement analytics tools to track KPIs, measure marketing effectiveness, and provide detailed reports to refine strategies."
      ],
    },
    developmentCycle: {
      title: "Digital Branding & Marketing Process",
      array:[
        "· Understand business goals, target audience, and current digital presence.",
        "· Formulate a tailored brand strategy based on market trends and audience insights.",
        "· Design a compelling brand identity, including logo, color palette, and typography.",
        "· Create and optimize a custom website aligned with your brand identity.",
        "· Develop engaging content tailored to your target audience and brand voice.",
        "· Strategically distribute content across relevant digital channels.",
        "· Manage social media profiles, post regularly, engage with the community, and track performance.",
        "· Plan and execute targeted advertising campaigns, optimizing for maximum ROI.",
        "· Track key performance indicators and provide detailed reporting to inform data-driven decisions."
      ] ,
    },
    whyChooseUs: {
      point:  [
        "· Experienced Team: Our team of digital marketing experts brings years of industry experience and a proven track record of success in helping businesses achieve their branding and marketing objectives.",
        "· Customized Solutions: We understand that every business is unique, which is why we tailor our services to your specific needs, goals, and budget constraints.",
        "· Measurable Results: We are committed to delivering measurable results and tangible ROI, providing you with transparent reporting and insights to track the performance of your digital marketing campaigns."
      ],
    },
  

  },
 
  {
    id:"actionable-intelligence",
    serviceHeader:"Actionable Intelligence",
    content:"Actionable Intelligence provides comprehensive data science development services that leverage predictive analytics and advanced AI technologies. Our solutions empower global businesses to make informed decisions and optimize operations for a wide range of business use cases.",
    image:ActionableIntelligence,
    keyFeatures:{
      feature:[
        "· Predictive Analytics: We develop predictive models that analyze data trends and provide actionable insights, helping you anticipate future outcomes and make proactive decisions.",
        "· Advanced AI Technologies: Our solutions incorporate cutting-edge AI technologies to enhance data analysis and decision-making processes.",
        "· Business Optimization: We create data-driven strategies that optimize business operations, improve efficiency, and drive growth.",
        "· Scalability: Our solutions are designed to scale with your business, ensuring consistent performance as your data needs grow.",
        "· Flexibility: We offer flexible solutions that can adapt to changing business requirements and integrate seamlessly with existing systems.",
        "· Security: We implement robust security measures to protect your data and maintain compliance with industry standards."
      ]
    },
    infrastructure: {
      title:
        "Our infrastructure is built on reliable and scalable cloud platforms, ensuring optimal performance and flexibility for your deep learning solutions.",
      points: [],
    },
    techStacks: {
      title: "Tech Stacks",
      array: [
        "· Data Science Frameworks: TensorFlow · PyTorch · scikit-learn",
        "· Programming Languages: Python · R",
        "· Database: MySQL · PostgreSQL · MongoDB · BigQuery",
        "· Visualization: Tableau · Power BI · D3.js",
        "· Advanced Technologies: Natural Language Processing (NLP) · Machine Learning · Predictive Analytics",
        "· DevOps: Docker · Kubernetes · Jenkins · AWS (Amazon Web Services) · Google Cloud Platform"
      ],
    },
    developmentTechniques: {
      title: "Development Techniques",
      array: [
        "· Agile Methodology: We follow agile development practices to ensure flexibility and adaptability throughout the development process.",
        "· Continuous Integration/Continuous Deployment (CI/CD): We automate the deployment process to streamline development and ensure rapid delivery of updates.",
        "· Test-Driven Development (TDD): We employ TDD practices to ensure code quality and minimize errors.",
        "· Code Reviews: Regular code reviews are conducted to maintain code quality and identify potential issues early on."
      ],
    },
    developmentCycle: {
      title: "Development Cycle",
      array: [
        "· Requirement Gathering: We collaborate with clients to gather requirements and establish clear project goals.",
        "· Planning: We create a comprehensive project plan, outlining timelines, milestones, and resource allocation.",
        "· Design: Our design team creates wireframes and prototypes, refining them based on client feedback.",
        "· Development: The development team starts building AI conversational tools, following best practices and coding standards.",
        "· Testing: We conduct thorough testing to identify and resolve bugs, ensuring the tools meet high-quality standards.",
        "· Deployment: The tools are deployed to production environments using a carefully planned rollout strategy.",
        "· Maintenance and Support: We offer continuous maintenance and support services to promptly address any issues and ensure seamless tool operation."
      ],
    },
    whyChooseUs: {
      point:  [
        "· Tailored Solutions: We design AI-driven solutions customized to meet your unique business needs, ensuring optimal and efficient performance.",
        "· Expertise Across Industries: Our extensive experience in various industries allows us to understand the complexities of different sectors and develop AI solutions that perfectly match your specific industry requirements.",
        "· Dedicated Team: Our skilled developers are committed to delivering high-quality, innovative solutions and providing continuous support and guidance throughout the development process.",
        "· Cutting-Edge Technologies: We employ the latest AI technologies and frameworks to create scalable, future-proof solutions that keep your business at the forefront of innovation.",
        "· Focus on User Experience: We prioritize designing intuitive and seamless user experiences to enhance user satisfaction and productivity.",
        "· Proven Track Record: With a history of successfully delivering projects on time and within budget, you can trust us to realize your AI vision with precision and excellence."
      ],
    },
  

  },
  {
    id:"ai-conversational-tools",
    serviceHeader:"AI Conversational Tools: Infusing Innovation",
    content:"Discover our state-of-the-art AI conversational tools, including ChatGPT, Midjourney, Dall-E, and chatbots. Passionately developed with industry-leading NLP and NLU accuracy, powered by our custom hybrid AI model, these tools provide interactive experiences that set new standards for innovation.",
    image:AIConversationalTools,
    keyFeatures:{
      feature:[
        "· ChatGPT: Our advanced conversational AI delivers human-like interactions, enhancing customer support, and engagement.",
        "· Midjourney: Innovative AI-powered tools for generating creative content and automating complex tasks.",
        "· Dall-E: State-of-the-art image generation capabilities that transform textual descriptions into vivid visual representations.",
        "· Chatbots: Custom-built chatbots designed to automate customer service, streamline operations, and improve user experience.",
        "· NLP and NLU Accuracy: Our tools are built with industry-leading Natural Language Processing (NLP) and Natural Language Understanding (NLU) technologies for accurate and context-aware interactions.",
        "· Custom Hybrid AI Model: Our proprietary hybrid AI model combines the best of different AI techniques to deliver superior performance and reliability."
      ]
    },
    infrastructure: {
      title:
        "Our infrastructure is built on reliable and scalable cloud platforms, ensuring optimal performance and flexibility for your deep learning solutions.",
      points: [],
    },
    techStacks: {
      title: "Tech Stacks",
      array: [
        "· AI Frameworks: TensorFlow · PyTorch · OpenAI GPT",
        "· Programming Languages: Python · JavaScript",
        "· Database: MySQL · PostgreSQL · MongoDB · BigQuery",
        "· Design: Adobe XD · Figma · Sketch",
        "· Advanced Technologies: Natural Language Processing (NLP) · Natural Language Understanding (NLU) · Image Generation",
        "· DevOps: Docker · Kubernetes · Jenkins · AWS (Amazon Web Services) · Google Cloud Platform"
      ],
    },
    developmentTechniques: {
      title: "Development Techniques",
      array: [
        "· Agile Methodology: We follow agile development practices to ensure flexibility and adaptability throughout the development process.",
        "· Continuous Integration/Continuous Deployment (CI/CD): We automate the deployment process to streamline development and ensure rapid delivery of updates.",
        "· Test-Driven Development (TDD): We employ TDD practices to ensure code quality and minimize errors.",
        "· Code Reviews: Regular code reviews are conducted to maintain code quality and identify potential issues early on."
      ],
    },
    developmentCycle: {
      title: "Development Cycle",
      array: [
        "· Requirement Gathering: We collaborate with clients to gather requirements and establish clear project goals.",
        "· Planning: We create a comprehensive project plan, outlining timelines, milestones, and resource allocation.",
        "· Design: Our design team creates wireframes and prototypes, refining them based on client feedback.",
        "· Development: The development team starts building AI conversational tools, following best practices and coding standards.",
        "· Testing: We conduct thorough testing to identify and resolve bugs, ensuring the tools meet high-quality standards.",
        "· Deployment: The tools are deployed to production environments using a carefully planned rollout strategy.",
        "· Maintenance and Support: We offer continuous maintenance and support services to promptly address any issues and ensure seamless tool operation."
      ],
    },
    whyChooseUs: {
      point:  [
        "· Tailored Solutions: We design AI-driven solutions customized to meet your unique business needs, ensuring optimal and efficient performance.",
        "· Expertise Across Industries: Our extensive experience in various industries allows us to understand the complexities of different sectors and develop AI solutions that perfectly match your specific industry requirements.",
        "· Dedicated Team: Our skilled developers are committed to delivering high-quality, innovative solutions and providing continuous support and guidance throughout the development process.",
        "· Cutting-Edge Technologies: We employ the latest AI technologies and frameworks to create scalable, future-proof solutions that keep your business at the forefront of innovation.",
        "· Focus on User Experience: We prioritize designing intuitive and seamless user experiences to enhance user satisfaction and productivity.",
        "· Proven Track Record: With a history of successfully delivering projects on time and within budget, you can trust us to realize your AI vision with precision and excellence."
      ],
    },
  

  },
  {
    id:"deep-learning",
    serviceHeader:"Deep Learning",
    content:"Deep Learning offers cutting-edge solutions that harness the power of neural networks to provide advanced capabilities in speech and facial recognition, automation, and business intelligence. Our team of experts specializes in developing deep learning models that enhance your business operations and decision-making processes.",
    image:DeepLearning,
    keyFeatures:{
      feature:[
        "· Speech Recognition: We develop sophisticated speech recognition systems that accurately transcribe and interpret spoken language, enhancing communication and accessibility.",
        "· Facial Recognition: Our advanced facial recognition solutions provide secure and efficient identification and authentication processes.",
        "· Automation: We create automated systems that streamline repetitive tasks, reducing manual effort and increasing operational efficiency.",
        "· Business Intelligence: Our deep learning models deliver powerful insights and predictive analytics, enabling informed business decisions.",
        "· Scalability: Our solutions are designed to scale with your business, ensuring consistent performance as your needs grow.",
        "· Security: We implement robust security measures to protect your data and maintain compliance with industry standards."
      ]
    },
    infrastructure: {
      title:
        "Our infrastructure is built on reliable and scalable cloud platforms, ensuring optimal performance and flexibility for your deep learning solutions.",
      points: [],
    },
    techStacks: {
      title: "Tech Stacks",
      array: [
        "· Deep Learning Frameworks: TensorFlow · PyTorch · Keras",
        "· Programming Languages: Python · R",
        "· Database: MySQL · PostgreSQL · MongoDB · BigQuery",
        "· Design: Adobe XD · Figma · Sketch",
        "· Advanced Technologies: Natural Language Processing (NLP) · Computer Vision · Predictive Analytics",
        "· DevOps: Docker · Kubernetes · Jenkins · AWS (Amazon Web Services) · Google Cloud Platform"
      ],
    },
    developmentTechniques: {
      title: "Development Techniques",
      array: [
        "· Agile Methodology: We follow agile development practices to ensure flexibility and adaptability throughout the development process.",
        "· Continuous Integration/Continuous Deployment (CI/CD): We automate the deployment process to streamline development and ensure rapid delivery of updates.",
        "· Test-Driven Development (TDD): We employ TDD practices to ensure code quality and minimize errors.",
        "· Code Reviews: Regular code reviews are conducted to maintain code quality and identify potential issues early on."
      ],
    },
    developmentCycle: {
      title: "Development Cycle",
      array: [
        "· Requirement Gathering: We collaborate with clients to gather requirements and establish clear project goals.",
        "· Planning: We create a comprehensive project plan, outlining timelines, milestones, and resource allocation.",
        "· Design: Our design team creates wireframes and prototypes, refining them based on client feedback.",
        "· Development: The development team starts building AI conversational tools, following best practices and coding standards.",
        "· Testing: We conduct thorough testing to identify and resolve bugs, ensuring the tools meet high-quality standards.",
        "· Deployment: The tools are deployed to production environments using a carefully planned rollout strategy.",
        "· Maintenance and Support: We offer continuous maintenance and support services to promptly address any issues and ensure seamless tool operation."
      ],
    },
    whyChooseUs: {
      point:  [
        "· Tailored Solutions: We design AI-driven solutions customized to meet your unique business needs, ensuring optimal and efficient performance.",
        "· Expertise Across Industries: Our extensive experience in various industries allows us to understand the complexities of different sectors and develop AI solutions that perfectly match your specific industry requirements.",
        "· Dedicated Team: Our skilled developers are committed to delivering high-quality, innovative solutions and providing continuous support and guidance throughout the development process.",
        "· Cutting-Edge Technologies: We employ the latest AI technologies and frameworks to create scalable, future-proof solutions that keep your business at the forefront of innovation.",
        "· Focus on User Experience: We prioritize designing intuitive and seamless user experiences to enhance user satisfaction and productivity.",
        "· Proven Track Record: With a history of successfully delivering projects on time and within budget, you can trust us to realize your AI vision with precision and excellence."
      ],
    },
  

  },
  {
    id:"custom-built-ai-development",
    serviceHeader:"Custom Built AI Development",
    content:"Custom-built AI Development offers specialized AI solutions tailored to your business needs. Our team of experts creates intelligent systems that enhance operational efficiency, optimize processes, and safeguard against threats through advanced AI technologies.",
    image:CustomBuiltAIDevelopment,
    keyFeatures:{
      feature:[
        "· Simulated Detection: Our AI solutions simulate detection systems to safeguard against operational deviations, ensuring smooth and efficient processes.",
        "· Autonomous Optimization: We develop autonomous systems that optimize your business operations, reducing manual effort and increasing efficiency.",
        "· Fraud Detection: Our AI frameworks provide robust fraud detection capabilities, protecting your business from potential threats and vulnerabilities.",
        "· Scalability: Our AI solutions are designed to scale with your business, ensuring consistent performance as your needs grow.",
        "· Flexibility: We create flexible solutions that can adapt to changing business requirements and integrate seamlessly with existing systems.",
        "· Security: We implement advanced security measures to protect your data and maintain compliance with industry standards."
      ]
    },
    infrastructure: {
      title:
        "Our infrastructure leverages dependable and scalable cloud platforms, guaranteeing optimal performance and flexibility for your AI-powered solutions.",
      points: [],
    },
    techStacks: {
      title: "Tech Stacks",
      array: [
  "· AI/ML Frameworks: TensorFlow · PyTorch · scikit-learn",
  "· Programming Languages: Python · R · Java",
  "· Database: MySQL · PostgreSQL · MongoDB · BigQuery",
  "· Design: Adobe XD · Figma · Sketch",
  "· Advanced Technologies: Natural Language Processing (NLP) · Computer Vision · Predictive Analytics",
  "· DevOps: Docker · Kubernetes · Jenkins · AWS (Amazon Web Services) · Google Cloud Platform"
],
    },
    developmentTechniques: {
      title: "Development Techniques",
      array: [
        "· Agile Methodology: We follow agile development practices to ensure flexibility and adaptability throughout the development process.",
        "· Continuous Integration/Continuous Deployment (CI/CD): We automate the deployment process to streamline development and ensure rapid delivery of updates.",
        "· Test-Driven Development (TDD): We employ TDD practices to ensure code quality and minimize errors.",
        "· Code Reviews: Regular code reviews are conducted to maintain code quality and identify potential issues early on."
      ],
    },
    developmentCycle: {
      title: "Development Cycle",
      array: [
        "· Requirement Gathering: We collaborate with clients to gather requirements and establish clear project goals.",
        "· Planning: We create a comprehensive project plan, outlining timelines, milestones, and resource allocation.",
        "· Design: Our design team creates wireframes and prototypes, refining them based on client feedback.",
        "· Development: The development team starts building AI conversational tools, following best practices and coding standards.",
        "· Testing: We conduct thorough testing to identify and resolve bugs, ensuring the tools meet high-quality standards.",
        "· Deployment: The tools are deployed to production environments using a carefully planned rollout strategy.",
        "· Maintenance and Support: We offer continuous maintenance and support services to promptly address any issues and ensure seamless tool operation."
      ],
    },
    whyChooseUs: {
      point:  [
        "· Tailored Solutions: We design AI-driven solutions customized to meet your unique business needs, ensuring optimal and efficient performance.",
        "· Expertise Across Industries: Our extensive experience in various industries allows us to understand the complexities of different sectors and develop AI solutions that perfectly match your specific industry requirements.",
        "· Dedicated Team: Our skilled developers are committed to delivering high-quality, innovative solutions and providing continuous support and guidance throughout the development process.",
        "· Cutting-Edge Technologies: We employ the latest AI technologies and frameworks to create scalable, future-proof solutions that keep your business at the forefront of innovation.",
        "· Focus on User Experience: We prioritize designing intuitive and seamless user experiences to enhance user satisfaction and productivity.",
        "· Proven Track Record: With a history of successfully delivering projects on time and within budget, you can trust us to realize your AI vision with precision and excellence."
      ],
    },
  

  },

  {
    id: 'web-app-development',
    serviceHeader: "Web App Development",
    content:
      "Our Web App Development service caters to businesses and individuals seeking to create dynamic, scalable, and secure web applications tailored to their specific needs. We specialize in crafting intuitive user experiences while ensuring robust functionality and seamless performance across devices and platforms.",
    image: WebDevelopment,
    keyFeatures: {
      feature: [
        "1. Customized Solutions: Tailored development to meet unique business requirements.",
        "2. Scalability: Built to accommodate growth and increased user demand.",
        "3. Security: Implementing industry-best practices to safeguard data and user privacy.",
        "4. Cross-Platform Compatibility: Ensuring optimal performance across various browsers and devices.",
        "5. Responsive Design: Ensuring a seamless user experience across desktop, tablet, and mobile devices.",
        "6. Integration Capabilities: Seamlessly integrate with third-party APIs and services.",
        "7. Optimized Performance: Utilizing efficient coding practices for fast loading times and smooth user interactions.",
        "8. Maintenance and Support: Providing ongoing maintenance and support to ensure smooth operation post-launch.",
      ],
    },
    infrastructure: {
      title:
        "Our development infrastructure is designed to support the entire development lifecycle, from initial concept to deployment and beyond. We leverage cloud-based platforms and robust development environments to facilitate collaboration and streamline the development process.",
      points: [],
    },
    techStacks: {
      title: "Tech Stacks",
      array: [
        "Frontend: HTML5, CSS3, JavaScript (React.js, Angular, Vue.js)",
        "Backend: Node.js, Java (Spring Boot), Django, Ruby on Rails, Express.js",
        "Database: MySQL, MongoDB, PostgreSQL",
        "Design: Adobe XD, Sketch, Figma",
        "Servers: Apache, Nginx",
        "DevOps: Docker, Kubernetes, Jenkins, Git, AWS (Amazon Web Services), Azure (Microsoft Azure), Google Cloud Platform",
        "Advanced Technologies: Progressive Web Apps (PWAs), Single Page Applications (SPAs), Microservices architecture",
      ],
    },
    developmentTechniques: {
      title: "Development Techniques",
      array: [
        "1. Agile Methodology: Iterative development cycles for rapid prototyping and continuous improvement.",
        "2. Test-Driven Development (TDD): Writing tests before code to ensure reliability and maintainability.",
        "3. Continuous Integration/Continuous Deployment (CI/CD): Automating the build, test, and deployment process for efficiency and reliability.",
        "4. Code Reviews: Regular peer reviews to maintain code quality and ensure adherence to best practices.",
        "5. Version Control: Utilizing Git for version control and collaboration among team members.",
      ],
    },
    developmentCycle: {
      title: "Development Cycle",
      array: [
        "1. Planning & Discovery: Understanding client requirements, defining project scope, and creating a roadmap.",
        "2. Design & Prototyping: Creating wireframes and prototypes to visualize the application's layout and functionality.",
        "3. Development: Writing clean, scalable code following industry best practices and coding standards.",
        "4. Testing: Conducting comprehensive testing, including unit tests, integration tests, and user acceptance testing (UAT).",
        "5. Deployment: Deploying the application to production environment and ensuring smooth transition.",
        "6. Maintenance & Support: Providing ongoing maintenance, updates, and support to address any issues and ensure optimal performance.",
      ],
    },
    whyChooseUs: {
      point: [
        "1. Expertise: Our team comprises seasoned professionals with years of experience in web app development, ensuring top-notch results",
        "2. Custom Solutions: We tailor our development process to your specific needs, crafting unique solutions that align perfectly with your business objectives.",
        "3. Cutting-Edge Technology: Utilizing the latest technologies and frameworks, we ensure that your web app stays ahead of the curve in terms of functionality and performance.",
        "4. User-Centric Design: Our designs prioritize user experience, resulting in intuitive interfaces that engage and delight your users.",
        "5. Scalability: We build scalable web apps that can grow with your business, accommodating increased traffic and functionality without compromising performance.",
        "6. Robust Security: Security is paramount, and we implement industry best practices to safeguard your web app and user data against potential threats.",
      ],
    },
  },
  {
    id: 'cloud-and-devops',
    serviceHeader: "Cloud & DevOps",
    content:
      "In today's digital landscape, leveraging cloud technologies and implementing efficient DevOps practices are crucial for businesses aiming to stay competitive and agile. Our Cloud & DevOps service provides comprehensive solutions to help you optimize your infrastructure, streamline development workflows, and accelerate time-to-market.",
    image: CloudDevops,
    keyFeatures: {
      feature: [
        "1. Cloud Infrastructure Setup and Management: We assist in setting up, configuring, and managing cloud infrastructure tailored to your specific needs, whether it's AWS, Azure, Google Cloud, or other cloud providers.",
        "2. Continuous Integration/Continuous Deployment (CI/CD): Implement CI/CD pipelines to automate the build, test, and deployment processes, ensuring faster and more reliable software delivery.",
        "3. Infrastructure as Code (IaC): Utilize tools like Terraform or AWS CloudFormation to define and provision infrastructure resources programmatically, enabling consistency, scalability, and repeatability.",
        "4. Containerization and Orchestration: Implement Docker containers and Kubernetes orchestration to package, deploy, and manage applications seamlessly across different environments.",
        "5. Monitoring and Logging: Set up robust monitoring and logging solutions using tools like Prometheus, Grafana, ELK stack (Elasticsearch, Logstash, Kibana) to ensure real-time visibility into system performance and issues.",
        "6. Security and Compliance: Implement best practices for security, including identity and access management (IAM), encryption, network security, and compliance with industry standards like GDPR, HIPAA, etc.",
      ],
    },
    infrastructure: {
      title:
        "Our development infrastructure is designed to support the entire development lifecycle, from initial concept to deployment and beyond. We leverage cloud-based platforms and robust development environments to facilitate collaboration and streamline the development process.",
      points: [
        "Cloud provider selection based on your requirements.",
        "Virtual machines (VMs), containers, serverless architecture as per workload.",
        "Networking configurations such as VPCs, subnets, security groups.",
        "Storage solutions like object storage, databases, file systems.",
      ],
    },
    techStacks: {
      title: "Tech Stacks",
      array: [
        "Cloud Platforms: Amazon Web Services (AWS), Microsoft Azure, Google Cloud Platform (GCP), IBM Cloud",
        "DevOps Tools: Version Control, Git, GitHub, GitLab, Bitbucket",
        "Continuous Integration/Continuous Deployment (CI/CD): Jenkins, CircleCI, Travis CI, GitLab CI",
        "Configuration Management: Ansible, Chef, Puppet",
        "Containerization and Orchestration: Docker, Kubernetes",
        "Monitoring and Logging: Prometheus, Grafana, Elastic Stack (ELK Stack), Splunk",
        "Cloud Management and Security: Terraform, AWS CloudFormation, Azure DevOps",
      ],
    },
    developmentTechniques: {
      title: "Development Techniques",
      array: [
        "1. Automate infrastructure management with IaC and use containers for consistent, scalable deployments.",
        "2. Automate code integration and deployment for quicker, smoother release cycles.",
        "3. Design applications as microservices for flexibility and optimize performance with dynamic scaling.",
        "4. Monitor applications in real-time, ensure robust logging, and integrate security practices into the development pipeline.",
      ],
    },
    developmentCycle: {
      title: "Development Cycle",
      array: [
        "1. Planning: Define project requirements, goals, and timelines.",
        "2. Design: Create architecture diagrams, UI/UX wireframes, and database schemas.",
        "3. Development: Implement features using selected tech stacks and development techniques.",
        "4. Testing: Conduct unit tests, integration tests, and system tests to ensure quality.",
        "5. Deployment: Deploy applications using CI/CD pipelines to staging and production environments.",
        "6. Monitoring & Optimization: Monitor performance, troubleshoot issues, and optimize resources for efficiency.",
        "7. Maintenance & Support: Provide ongoing maintenance, updates, and support services.",
      ],
    },
    whyChooseUs: {
      point: [
        "1. Leverage our deep knowledge of AWS, Azure, and Google Cloud, along with comprehensive DevOps services for efficient software delivery.",
        "2. Embrace agile methodologies for faster development and better collaboration, while optimizing cloud spending for cost savings.",
        "3. Benefit from robust security measures and scalable solutions that adjust resources as your business grows or fluctuates.",
        "4. Ensure peak efficiency with continuous monitoring and optimization, along with 24/7 dedicated support to address issues promptly.",
      ],
    },
  },
  {
    id: 'mobile-app-development',
    serviceHeader: "Mobile App Development",
    content:
      "Mobile App Development is the process of creating software applications that run on mobile devices such as smartphones and tablets. At Saintsmen Systems, we specialize in crafting innovative and user-friendly mobile apps tailored to meet the unique needs of our clients and their target audiences.",
    image: MobileAppDevelopment,
    keyFeatures: {
      feature: [
        "1. User-Centric Design: We prioritize intuitive user interfaces and seamless user experiences to ensure high engagement and satisfaction.",
        "2. Cross-Platform Compatibility: Our apps are developed to run smoothly on both iOS and Android platforms, maximizing your app's reach.",
        "3. Scalability: We design apps with scalability in mind, allowing them to grow and adapt as your business evolves.",
        "4. Performance Optimization: Through meticulous optimization techniques, we ensure fast loading times and smooth performance, even on devices with limited resources.",
        "5. Security: We implement robust security measures to protect user data and safeguard against potential vulnerabilities.",
        "6. Integration Capabilities: Our apps seamlessly integrate with third-party services and APIs, enhancing functionality and user experience.",
      ],
    },
    infrastructure: {
      title: "Our Mobile App Development infrastructure includes:",
      points: [
        "Development Environments: Utilizing industry-leading IDEs and development tools.",
        "Version Control Systems: Employing Git for efficient collaboration and code management.",
        "Testing Frameworks: Implementing automated testing frameworks for comprehensive quality assurance.",
      ],
    },
    techStacks: {
      title: "Tech Stacks",
      array: [
        "Frontend Frameworks: React Native, Flutter, Swift (for iOS), Kotlin (for Android)",
        "Backend Technologies: Node.js, Python (Django, Flask), Ruby (Ruby on Rails), Java (Spring Boot)",
        "Database Systems: Firebase, SQLite (for local storage), MongoDB (for NoSQL), PostgreSQL, MySQL",
        "UI/UX Design Tools: Adobe XD, Figma, Sketch",
        "Advanced Technologies Integration: Artificial Intelligence/Machine Learning, Blockchain, Internet of Things (IoT), Augmented Reality/Virtual Reality (AR/VR).",
      ],
    },
    developmentTechniques: {
      title: "Development Techniques",
      array: [
        "1. Agile Methodology: We follow an Agile approach to development, allowing for iterative and incremental progress, frequent feedback, and adaptability to changing requirements.",
        "2. Continuous Integration/Continuous Deployment (CI/CD): Our streamlined CI/CD pipelines ensure rapid and reliable deployment of updates and new features.",
        "3. Responsive Design: We employ responsive design principles to ensure optimal performance and usability across various screen sizes and devices.",
      ],
    },
    developmentCycle: {
      title: "Development Cycle",
      array: [
        "1. Requirement Gathering: We collaborate closely with clients to understand their vision, goals, and specific requirements.",
        "2. Planning & Design: Our design team creates wireframes and prototypes, mapping out the app's user flow and interface design.",
        "3. Development: Our experienced developers bring the design to life, coding the frontend, backend, and integrating necessary APIs and services.",
        "4. Testing & Quality Assurance: Rigorous testing is conducted to identify and rectify any bugs or performance issues.",
        "5. Deployment & Launch: Once the app is thoroughly tested and approved, we deploy it to the respective app stores and assist with the launch process.",
        "6. Maintenance & Support: We provide ongoing maintenance and support services to ensure the app remains up-to-date, secure, and optimized for performance.",
      ],
    },
    whyChooseUs: {
      point: [
        "1. Leverage our seasoned developers for high-quality mobile app development tailored to your specific business needs.",
        "2. Utilize the latest technologies and focus on intuitive, engaging user experiences for higher adoption rates.",
        "3. Enjoy scalable solutions with transparent communication and ongoing post-launch maintenance to keep your app optimized and up-to-date.",
      ],
    },
  },

  {
    id: 'software-testing',
    serviceHeader: "Software Testing",
    content:
      "Software Testing is a critical phase in the software development lifecycle (SDLC) aimed at ensuring that the software product meets the specified requirements and functions flawlessly. Our Software Testing service encompasses a comprehensive suite of testing methodologies, tools, and practices to deliver high-quality, reliable, and bug-free software solutions.",
    image: SoftwareTesting,
    keyFeatures: {
      feature: [
        "1. Comprehensive Testing Approach: We employ a multi-faceted testing strategy covering various aspects such as functionality, performance, security, compatibility, and usability.",
        "2. Automation Testing: Leveraging industry-leading automation tools, we streamline testing processes, enhance efficiency, and accelerate time-to-market.",
        "3. Custom Test Plans: Tailored test plans are crafted based on project requirements, ensuring thorough coverage and meticulous testing of all software components.",
        "4. Regression Testing: We conduct regular regression tests to identify and mitigate any unintended impacts on existing functionalities after software updates or modifications.",
        "5. Scalability Testing: Our testing methodology includes scalability assessments to ascertain the software's ability to handle increasing loads and user volumes.",
        "6. Security Testing: Robust security testing procedures are implemented to identify and rectify vulnerabilities, safeguarding the software against potential threats.",
        "7. Performance Tuning: We optimize software performance through meticulous testing, fine-tuning code, and addressing performance bottlenecks.",
        "8. Compliance Testing: Ensure adherence to industry standards, regulations, and best practices through compliance testing procedures.",
      ],
    },
    infrastructure: {
      title:
        "Our testing infrastructure is equipped with state-of-the-art tools, technologies, and resources to facilitate seamless testing operations. This includes:",
      points: [
        "High-performance servers for conducting load and stress testing.",
        "Virtualized environments to simulate diverse user scenarios.",
        "Cloud-based testing platforms for scalability and flexibility.",
        "Robust network infrastructure for conducting distributed testing.",
      ],
    },
    techStacks: {
      title: "Tech Stacks",
      array: [
        "Test Automation Tools: Selenium, Appium, TestComplete",
        "Test Management Tools: Jira, TestRail, HP Quality Center",
        "Continuous Integration/Continuous Deployment (CI/CD) Tools: Jenkins, GitLab CI, Travis CI",
        "Performance Testing Tools: JMeter, LoadRunner, Gatling",
        "Bug Tracking Tools: Bugzilla, Mantis, Redmine",
        "Virtualization and Containerization: Docker, Kubernetes, VirtualBox",
        "API Testing Tools: Postman, SoapUI, Rest Assured",
        "Browser Testing Tools: BrowserStack, CrossBrowserTesting, Sauce Labs",
        "Code Version Control Systems: Git, SVN",
      ],
    },
    developmentTechniques: {
      title: "Development Techniques",
      array: [
        "1. Use an iterative approach for continuous feedback and adaptation, with plain language scenarios to enhance collaboration.",
        "2. Write tests before code for quality assurance and perform unscripted testing to uncover defects through exploration.",
        "3. Assess system speed, stability, and responsiveness, while identifying vulnerabilities to ensure data protection and integrity.",
        "4. Validate interactions between combined modules and evaluate user interface for ease of use and effectiveness.",
      ],
    },
    developmentCycle: {
      title: "Development Cycle",
      array: [
        "1. Requirement Analysis: Gather and analyze project requirements to define test objectives and scope.",
        "2. Test Planning: Develop comprehensive test plans outlining testing strategies, methodologies, and resources.",
        "3. Test Design: Create test cases, scenarios, and scripts based on defined requirements and use cases.",
        "4. Test Execution: Conduct tests according to the predefined plan, document results, and report any anomalies.",
        "5. Defect Tracking: Log and prioritize identified defects, collaborate with development teams for resolution.",
        "6. Regression Testing: Perform regression tests to ensure no new defects are introduced post-fixing.",
        "7. Reporting and Documentation: Generate detailed test reports, documenting testing activities, results, and recommendations.",
        "8. Feedback and Optimization: Gather feedback from stakeholders, refine testing processes, and optimize for continuous improvement.",
      ],
    },
    whyChooseUs: {
      point: [
        "1. Expertise and Tailored Strategies: We excel in diverse testing methodologies and customize approaches to meet your project's unique needs.",
        "2. Quality Assurance and Advanced Tools: Employ robust processes with the latest tools and frameworks for efficient, effective testing and a seamless user experience.",
        "3. Performance and Comprehensive Coverage: Optimize software performance and ensure thorough test coverage across functionality, usability, security, and compatibility.",
        "4. Transparent Reporting and Scalable Solutions: Provide regular updates and detailed reports, offering adaptable services to fit your project's size and needs.",
      ],
    },
  },

  {
    id: 'ai-and-ml-services',
    serviceHeader: "AI and ML Services",
    content:
      "Our AI and ML services offer cutting-edge solutions to empower businesses with intelligent automation, predictive analytics, and data-driven decision-making. Leveraging the latest advancements in artificial intelligence and machine learning, we provide bespoke solutions tailored to your specific needs.",
    image: AIMLServices,
    keyFeatures: {
      feature: [
        "1. Predictive Analytics: Harness the power of predictive modeling to forecast trends, anticipate customer behavior, and optimize business strategies.",
        "2. Intelligent Automation: Automate repetitive tasks, streamline workflows, and enhance operational efficiency through AI-powered algorithms.",
        "3. Personalized Recommendations: Deliver personalized experiences to your users through recommendation engines driven by machine learning algorithms.",
        "4. Anomaly Detection: Detect anomalies in real-time data streams, enabling proactive risk management and fraud detection.",
        "5. Natural Language Processing (NLP): Understand and interpret human language to extract insights, sentiment analysis, and automate text-based processes.",
        "6. Computer Vision: Enable machines to perceive and understand visual information, powering applications like image recognition, object detection, and facial recognition.",
      ],
    },
    infrastructure: {
      title: "Infrastructure",
      points: [
        "Cloud Platforms: Leveraging AWS, GCP, and Azure for scalable computing resources.",
        "Data Warehouses: Utilizing Redshift, BigQuery, and Snowflake for efficient data management.",
        "GPUs and TPUs: Accelerating deep learning model training with NVIDIA GPUs and Google TPUs.",
        "Kubernetes: Automating deployment and scaling of containerized applications for reliability.",
        "Hadoop and Spark: Handling big data challenges with distributed storage and real-time processing.",
        "Docker: Ensuring consistency in application development and deployment through containers.",
        "ML Pipelines: Automating workflows and monitoring ML models with TensorFlow Extended and MLflow.",
      ],
    },
    techStacks: {
      title: "Tech Stacks",
      array: [
        "Python: Our go-to for data manipulation and visualization, with extensive libraries like NumPy, Pandas, and Matplotlib.",
        "TensorFlow: Google's open-source framework, essential for developing sophisticated neural networks and deep learning applications.",
        "PyTorch: Preferred for its dynamic computational graph and user-friendly interface, making it ideal for rapid prototyping.",
        "Scikit-learn: Utilized for implementing robust traditional machine learning algorithms, including regression, classification, and clustering.",
        "Keras: A high-level neural networks API designed for human interaction, running atop TensorFlow for streamlined model building.",
      ],
    },
    developmentTechniques: {
      title: "Development Techniques",
      array: [
        "Deep Learning: Utilizing neural networks to model complex patterns and decision functions.",
        "Supervised Learning: Training models on labeled data to predict outcomes accurately.",
        "Unsupervised Learning: Identifying hidden patterns and structures in unlabeled data.",
        "Reinforcement Learning: Developing systems that improve their performance based on feedback from interactions.",
        "Advanced Analytics: Applying techniques such as Natural Language Processing (NLP), Computer Vision, Predictive Analytics, Anomaly Detection, and Model Optimization/Hyperparameter Tuning to solve complex problems and enhance decision-making processes.",
      ],
    },
    developmentCycle: {
      title: "Development Cycle",
      array: [
        "Requirement Analysis: Collaborate with stakeholders to gather requirements and define project objectives.",
        "Prototyping: Develop prototypes to validate concepts and gather feedback early in the development process.",
        "Model Development: Design and develop machine learning models tailored to specific use cases, leveraging state-of-the-art algorithms and libraries.",
        "Integration: Integrate AI and ML models into the application architecture, ensuring seamless interaction with other components.",
        "Conduct rigorous testing, including unit tests, integration tests, and performance tests, to validate functionality and ensure reliability.",
        "Deploy the application to production or staging environments using automated CI/CD pipelines for efficient and reliable deployment.",
        "Monitoring and Optimization: Monitor application performance and model behavior in production, and iterate on improvements based on real-world usage and feedback.",
      ],
    },
    whyChooseUs: {
      point: [
        "Expertise and Innovation: Seasoned AI and ML professionals delivering cutting-edge solutions with the latest advancements.",
        "Customized and Scalable Solutions: Personalized AI and ML services tailored to your unique challenges, designed for seamless growth.",
        "Data Security and Transparency: Robust data security measures with transparent communication throughout the AI and ML process.",
        "Proven Success and Ongoing Support: Successful AI and ML implementations with continuous support and maintenance for smooth operation.",
        "Cost-Effective and Client-Centric: Competitive pricing without quality compromise, focusing on meeting and exceeding client expectations.",
        "Adaptability and Collaboration: Stay adaptable with updated skills and methodologies, fostering a collaborative partnership to achieve shared goals.",
      ],
    },
  },

  {
    id: 'staff-augmentation',
    serviceHeader: "Staff Augmentation",
    content:
      "Staff augmentation is a strategic approach for businesses to scale their teams efficiently by supplementing their existing workforce with skilled professionals on a temporary basis. Our staff augmentation services are designed to provide you with the flexibility and expertise needed to meet your project demands without the long-term commitment of hiring full-time employees.",
    image: StaffAugumentation,
    keyFeatures: {
      feature: [
        "1. Flexible Resource Allocation: Scale your team up or down according to project requirements, ensuring optimal resource utilization.",
        "2. Access to Specialized Skills: Gain access to a diverse pool of talented professionals with expertise in various domains and technologies.",
        "3. Cost-Effective Solution: Reduce overhead costs associated with recruiting, onboarding, and maintaining full-time employees.",
        "4. Quick Deployment: Expedite project timelines by quickly onboarding qualified professionals who are ready to contribute from day one.",
        "5. Enhanced Productivity: Augment your team with skilled professionals to alleviate workload bottlenecks and improve project efficiency.",
        "6. Seamless Integration: Our staff augmentation process ensures seamless integration of external resources into your existing team structure and workflows.",
        "7. Risk Mitigation: Mitigate risks associated with project delays or skill shortages by leveraging our extensive network of experienced professionals.",
      ],
    },
    infrastructure: {
      title:
        "Our staff augmentation services are supported by robust infrastructure and resources to facilitate smooth collaboration and project execution:",
      points: [
        "Communication Tools: Utilize cutting-edge communication tools and platforms to ensure real-time collaboration and seamless interaction between your team and augmented staff.",
        "Project Management Systems: Access comprehensive project management systems for tracking progress, managing tasks, and maintaining transparency throughout the project lifecycle.",
        "Security Measures: Implement stringent security measures to safeguard sensitive data and ensure compliance with industry regulations.",
        "Training and Development: Provide ongoing training and development opportunities to ensure that augmented staff stay updated with the latest technologies and best practices.",
      ],
    },
    techStacks: {
      title: "How it Works",
      array: [
        "Initial Consultation: We conduct a detailed consultation to understand your project requirements, timelines, and resource needs.",
        "Resource Identification: Our team identifies and selects the most suitable candidates from our talent pool based on your specific requirements and preferences.",
        "Onboarding: Selected professionals undergo a seamless onboarding process, including orientation, training (if necessary), and integration into your team.",
        "Project Execution: Augmented staff seamlessly integrate into your project team, contributing their expertise to meet project milestones and deliverables.",
      ],
    },
    developmentTechniques: {
      title: "How it Works",
      array: [
        "1. Performance Monitoring: We continuously monitor the performance of augmented staff, providing feedback and support to ensure optimal productivity and quality of work.",
        "2. Project Completion: Upon project completion or as per your requirements, augmented staff are transitioned out smoothly, ensuring minimal disruption to your operations.",
      ],
    },
    developmentCycle: {
      title: "Staffing Process",
      array: [
        "1. We start with a detailed consultation to understand your project requirements, timelines, and resource needs.",
        "2. Our team identifies and selects the most suitable candidates from our diverse talent pool.",
        "3. The selected professionals undergo a seamless onboarding process, including orientation and necessary training.",
        "4. Augmented staff integrate smoothly into your project team, working alongside your existing employees.",
        "5. We continuously monitor the performance of the augmented staff, providing regular feedback and support.",
        "6. Upon project completion or as per your requirements, we ensure a smooth transition of the augmented staff out of your team.",
      ],
    },
    whyChooseUs: {
      point: [
        "1. Expertise: Benefit from our extensive network of highly skilled professionals with expertise across various industries and technologies.",
        "2. Flexibility: Enjoy the flexibility to scale your team up or down as per your project requirements, without the long-term commitment of hiring full-time employees.",
        "3. Quality Assurance: We prioritize quality assurance at every step, ensuring that only the most qualified professionals are selected to augment your team.",
        "4. Dedicated Support: Our team provides dedicated support throughout the engagement, ensuring that your project requirements are met efficiently and effectively.",
      ],
    },
  },

  {
    id: 'block-chain-solutions',
    serviceHeader: "Blockchain Solutions",
    content:
      "Blockchain Solutions is a cutting-edge service aimed at revolutionizing industries through the power of decentralized ledger technology. Our team specializes in harnessing the potential of blockchain to streamline processes, enhance security, and foster transparency in various sectors.",
    image: BlockChainServices,
    keyFeatures: {
      feature: [
        "1. Decentralization: Utilize the decentralized nature of blockchain to eliminate single points of failure and enhance data integrity.",
        "2. Immutability: Ensure that once data is recorded on the blockchain, it cannot be altered or tampered with, providing a secure audit trail.",
        "3. Transparency: Enable transparent and traceable transactions, fostering trust among participants.",
        "4. Smart Contracts: Implement self-executing contracts to automate processes and reduce reliance on intermediaries.",
        "5. Tokenization: Facilitate the creation and management of digital assets through tokenization, opening up new avenues for value exchange.",
        "6. Interoperability: Build solutions that can seamlessly interact with existing systems and other blockchain networks.",
        "7. Scalability: Develop scalable blockchain architectures to support high transaction volumes without compromising performance.",
      ],
    },
    infrastructure: {
      title:
        "Our infrastructure is designed to ensure optimal performance, security, and scalability:",
      points: [
        "Network Architecture: Utilize a combination of public and private blockchain networks based on the specific requirements of the project.",
        "Node Deployment: Set up distributed nodes to maintain the integrity of the network and facilitate consensus mechanisms.",
        "Security Measures: Implement robust security protocols, including encryption, multi-factor authentication, and consensus algorithms, to safeguard data and transactions.",
      ],
    },
    techStacks: {
      title: "Tech Stacks",
      array: [
        "Blockchain Platforms: Ethereum, Hyperledger Fabric, Corda",
        "Smart Contract Development: Solidity, Chaincode (GoLang), JavaScript",
        "Consensus Mechanisms: Proof of Work (PoW), Proof of Stake (PoS), Practical Byzantine Fault Tolerance (PBFT)",
        "Wallet Development: Web3.js, MetaMask, Truffle",
        "Token Standards: ERC-20, ERC-721, ERC-1155",
        "Decentralized Applications (DApps): React.js, Angular, Node.js",
        "Interoperability Protocols: Atomic Swaps, Sidechains, Interledger Protocol (ILP)",
      ],
    },
    developmentTechniques: {
      title: "Development Techniques",
      array: [
        "1. Smart Contracts Development: Write self-executing contractual states stored on blockchain to automatically enforce and execute terms of agreement.",
        "2. DApp Development: Create decentralized applications that operate on a peer-to-peer network of computers, enhancing reliability and decentralization.",
        "3. Tokenization: Issue blockchain tokens representing real-world assets, ensuring secure and streamlined transactions.",
        "4. Consensus Algorithms Implementation: Develop algorithms like Proof of Work or Proof of Stake to achieve consensus on the blockchain network, ensuring reliability and integrity of the ledger.",
      ],
    },
    developmentCycle: {
      title: "Development Cycle",
      array: [
        "1. Discovery Phase: Collaborate with stakeholders to define project requirements, goals, and success criteria.",
        "2. Design Phase: Create wireframes, prototypes, and architecture diagrams to visualize the solution and gather feedback.",
        "3. Development Phase: Implement the solution using the chosen technologies and development techniques.",
        "4. Testing Phase: Conduct rigorous testing, including unit tests, integration tests, and user acceptance testing, to ensure quality and reliability.",
        "5. Deployment Phase: Deploy the solution to production environments, monitor performance, and address any issues that arise.",
        "6. Maintenance and Support: Provide ongoing maintenance, updates, and support to ensure the long-term success of the solution.",
      ],
    },
    whyChooseUs: {
      point: [
        "1. Extensive Expertise and Proven Track Record: Rely on our team's deep knowledge and experience in blockchain technology. Our history of successful implementations across diverse industries speaks for itself.",
        "2. Security-Centric Approach and Tailored Solutions: Your data and transactions are safeguarded by our rigorous security measures. We craft bespoke blockchain solutions precisely tailored to fit your unique requirements.",
        "3. Scalability & Reliability: Our solutions are built to grow with your business, ensuring optimal performance and reliability.",
        "4. Transparent Communication and Cost-Efficiency: Expect clear and open communication at every stage of your project journey. Achieve your blockchain goals within your budget without compromising quality.",
        "5. Continual Support and Expert Consultation: Our support doesn't end at deployment; we're here to ensure ongoing success. Access our knowledgeable team for guidance on blockchain integration and strategy.",
        "6. Future-Ready Solutions: Stay ahead of the curve with solutions designed to evolve alongside technology trends, ensuring your business remains competitive in the blockchain landscape.",
      ],
    },
  },

  {
    id: 'digital-branding-and-marketing',
    serviceHeader: "Digital Branding and Marketing",
    content:
      "In today's digital age, establishing a strong brand presence and effectively marketing it online is crucial for businesses to thrive. Our Digital Branding and Marketing service is designed to help businesses enhance their online visibility, connect with their target audience, and drive meaningful engagement and conversions.",
    image: DigitalBranding,
    keyFeatures: {
      feature: [
        "1. Brand Strategy Development: Tailored brand strategy to define your unique value proposition and positioning in the digital landscape. Comprehensive analysis of market trends, competitor strategies, and audience insights to formulate a winning brand strategy.",
        "2. Brand Identity Design: Creation of a compelling brand identity, including logo design, color palette selection, typography, and brand guidelines. Ensuring consistency across all digital platforms to reinforce brand recognition and recall.",
        "3. Website Development and Optimization: Custom website design and development aligned with your brand identity and business goals.Implementation of SEO best practices to improve search engine visibility and drive organic traffic.",
        "4. Content Creation and Marketing: Development of engaging content tailored to your target audience and brand voice, including blog posts, social media content, videos, and infographics. Strategic content distribution across relevant digital channels to maximize reach and engagement.",
        "5. Social Media Management: Creation and management of social media profiles on platforms such as Facebook, Instagram, Twitter, LinkedIn, and more. Regular posting, community engagement, and performance tracking to build brand awareness and foster customer relationships.",
        "6. Paid Advertising Campaigns: Strategic planning and execution of targeted advertising campaigns across various digital channels, including Google Ads, social media ads, and display advertising.",
        "7. Analytics and Reporting: Implementation of advanced analytics tools to track key performance indicators (KPIs) and measure the effectiveness of digital marketing efforts. Detailed reporting and insights to inform data-driven decision-making and refine marketing strategies.",
      ],
    },
    infrastructure: {
      title: "",
      points: [
        "Advanced Marketing Tools: Utilization of cutting-edge digital marketing tools and technologies to streamline workflows, automate processes, and deliver superior results.",
        "Secure Data Management: Implementation of robust data security measures to safeguard sensitive information and ensure compliance with data protection regulations.",
        "Scalable Infrastructure: Flexible and scalable infrastructure to accommodate the evolving needs of your business and support future growth and expansion.",
      ],
    },
    techStacks: {
      title: "How it Works",
      array: [
        "We begin with an in-depth consultation to understand your business goals, target audience, and current digital presence.",
        "We analyze market trends, competitor strategies, and audience insights to develop a tailored brand strategy.",
        "Our team designs a compelling brand identity, including logo, color palette, typography, and brand guidelines.",
        "We create a custom website aligned with your brand identity and optimize it for search engines to drive organic traffic.",
        "We develop engaging content tailored to your audience and strategically distribute it across digital channels.",
      ],
    },
    developmentTechniques: {
      title: "How it Works",
      array: [
        "1. We manage your social media profiles, post regularly, engage with the community, and track performance.",
        "2. We plan and execute targeted advertising campaigns and continuously optimize them to maximize ROI.",
        "2. We implement analytics tools to track KPIs, measure marketing effectiveness, and provide detailed reports to refine strategies.",
      ],
    },
    developmentCycle: {
      title: "Digital Branding & Marketing Process",
      array: [
        "Understand business goals, target audience, and current digital presence.",
        "Formulate a tailored brand strategy based on market trends and audience insights.",
        "Design a compelling brand identity, including logo, color palette, and typography.",
        "Create and optimize a custom website aligned with your brand identity",
        "Develop engaging content tailored to your target audience and brand voice.",
        "Strategically distribute content across relevant digital channels.",
        "Manage social media profiles, post regularly, engage with the community, and track performance.",
        "Plan and execute targeted advertising campaigns, optimizing for maximum ROI.",
        "Track key performance indicators and provide detailed reporting to inform data-driven decisions.",
      ],
    },
    whyChooseUs: {
      point: [
        "1. Experienced Team: Our team of digital marketing experts brings years of industry experience and a proven track record of success in helping businesses achieve their branding and marketing objectives.",
        "2. Customized Solutions: We understand that every business is unique, which is why we tailor our services to your specific needs, goals, and budget constraints.",
        "3. Measurable Results: We are committed to delivering measurable results and tangible ROI, providing you with transparent reporting and insights to track the performance of your digital marketing campaigns.",
        "4. Dedicated Support: Our team provides dedicated support throughout the engagement, ensuring that your marketing strategy is executed efficiently and effectively.",
      ],
    },
  },

  {
    id: 'talent-resourcing',
    serviceHeader: "Talent Resourcing",
    content:
      "Talent Resourcing is a comprehensive service designed to assist businesses in finding and acquiring the right talent for their specific needs. Our goal is to streamline the recruitment process, saving you time and resources while ensuring you find the perfect fit for your team.",
    image: DigitalBranding2,
    keyFeatures: {
      feature: [
        "1. Customized Recruitment Strategies: We work closely with your organization to develop tailored recruitment strategies aligned with your business objectives and culture.",
        "2. Extensive Candidate Sourcing: Leveraging our vast network and advanced sourcing techniques, we identify top talent from diverse backgrounds and industries.",
        "3. Thorough Screening Process: Each candidate undergoes a rigorous screening process, including resume review, skills assessments, background checks, and in-depth interviews.",
        "4. Personalized Candidate Matching: Our experienced recruiters carefully match candidates based on skills, experience, personality fit, and cultural alignment.",
        "5. Efficient Hiring Workflow: We streamline the hiring process by managing communication between candidates and hiring managers, scheduling interviews, and facilitating feedback loops.",
      ],
    },
    infrastructure: {
      title: "",
      points: [
        "Advanced Technology Platform: Our proprietary technology platform integrates cutting-edge recruitment tools and analytics to enhance efficiency and accuracy.",
        "Dedicated Recruitment Team: Our team of skilled recruiters combines industry expertise with a passion for connecting talent with opportunity.",
        "Quality Assurance Protocols: We adhere to strict quality assurance protocols to uphold the highest standards of professionalism and integrity.",
        "Continuous Improvement Initiatives: We actively seek feedback from clients and candidates to identify areas for enhancement and evolve our service offerings accordingly.",
      ],
    },
    techStacks: {
      title: "How it Works",
      array: [
        "We start with a detailed consultation to understand your business objectives, team culture, and specific talent needs.",
        "We develop customized recruitment strategies tailored to your organization's goals and cultural fit.",
        "Using our extensive network and advanced sourcing techniques, we identify top talent from various industries and backgrounds.",
        "Each candidate undergoes a rigorous screening process, including resume reviews, skills assessments, background checks, and in-depth interviews.",
      ],
    },
    developmentTechniques: {
      title: "How it Works",
      array: [
        "1. Our experienced recruiters carefully match candidates based on their skills, experience, personality fit, and alignment with your company culture.",
        "2. We manage communication between candidates and hiring managers, schedule interviews, and facilitate feedback loops to ensure a smooth hiring process.",
      ],
    },
    developmentCycle: {
      title: "Talent Resourcing Process",
      array: [
        "Understand business objectives, team culture, and specific talent needs.",
        "Develop customized recruitment strategies tailored to your organization's goals and cultural fit.",
        "Identify top talent using our extensive network and advanced sourcing techniques.",
        "Conduct rigorous screening of candidates, including resume reviews, skills assessments, background checks, and interviews.",
        "Match candidates based on skills, experience, personality fit, and cultural alignment.",
        "Manage communication, schedule interviews, and facilitate feedback loops for a smooth hiring process.",
      ],
    },
    whyChooseUs: {
      point: [
        "1. Expertise: With years of experience in talent acquisition, we have the knowledge and skills to deliver results.",
        "2. Customization: Tailoring our approach to meet your specific requirements ensures a personalized experience and optimal outcomes.",
        "3. Efficiency: Our streamlined processes and advanced technology enable us to expedite hiring timelines without compromising quality.",
        "4. Partnership: We prioritize building long-term relationships based on trust, transparency, and mutual success.",
      ],
    },
  },

  {
    id: 'consulting',
    serviceHeader: "Consulting",
    content:
      "At Saintsmen Systems, we offer comprehensive consulting services designed to empower businesses and individuals to achieve their goals and overcome challenges. With a team of experienced consultants and industry experts, we provide strategic guidance, innovative solutions, and personalized support to help our clients thrive in today's competitive landscape.",
    image: Consulting,
    keyFeatures: {
      feature: [
        "1. Customized Strategy Development: We work closely with each client to understand their unique needs, goals, and challenges, and develop tailored strategies to address them effectively.",
        "2. Industry Expertise: Our consultants bring deep industry knowledge and experience across various sectors, including IT, Travel, Transportation etc. enabling us to offer valuable insights and actionable recommendations.",
        "3. Market Analysis and Research: We conduct thorough market analysis and research to identify opportunities, trends, and potential risks, ensuring our clients make informed decisions.",
        "4. Business Process Optimization: We help streamline business processes, improve efficiency, and maximize productivity through innovative solutions and best practices.",
        "5. Risk Management and Compliance: Our consultants assist clients in identifying and mitigating risks, ensuring compliance with regulatory requirements, and fostering a culture of accountability and transparency.",
        "6. Technology Integration: We provide guidance on leveraging technology to enhance operations, streamline workflows, and drive digital transformation initiatives.",
        "7. Change Management Support: We offer change management support to help organizations navigate transitions smoothly, minimize disruption, and foster employee buy-in and engagement.",
        "8. Continuous Support and Monitoring: Our commitment doesn't end with the implementation of solutions. We provide ongoing support, monitoring, and refinement to ensure sustained success and continued growth.",
      ],
    },
    infrastructure: {
      title: "",
      points: [
        "Experienced Team: Our team comprises seasoned consultants with diverse backgrounds and expertise, allowing us to offer comprehensive support across various industries and disciplines.",
        "Cutting-Edge Tools and Technologies: We utilize the latest tools, technologies, and methodologies to deliver high-quality consulting services efficiently and effectively.",
        "Collaborative Workspace: Our collaborative workspace facilitates seamless communication and collaboration among team members and clients, enabling us to work together towards shared objectives.",
        "Data Security Measures: We prioritize data security and confidentiality, implementing robust measures to safeguard sensitive information and comply with regulatory requirements.",
        "Quality Assurance Processes: We have stringent quality assurance processes in place to ensure the accuracy, reliability, and integrity of our consulting services, delivering exceptional value to our clients.",
      ],
    },
    techStacks: {
      title: "How it Works",
      array: [
        "We begin with an in-depth consultation to understand your unique needs, goals, and challenges.",
        "We develop customized strategies tailored to your specific requirements, leveraging our industry expertise.",
        "We conduct thorough market analysis to identify opportunities, trends, and potential risks.",
        "We help streamline business processes, integrate technology, and provide change management support for smooth transitions.",
        "We assist in identifying and mitigating risks, ensuring compliance with regulatory requirements.",
      ],
    },
    developmentTechniques: {
      title: "How it Works",
      array: [
        "1. We offer ongoing support, monitoring, and refinement to ensure sustained success and continued growth.",
      ],
    },
    developmentCycle: {
      title: "Consulting Process",
      array: [
        "Understand client needs, goals, and challenges.",
        "Develop customized strategies tailored to client requirements.",
        "Conduct thorough market analysis to identify opportunities and risks.",
        "Streamline business processes, integrate technology, and support change management.",
        "Identify and mitigate risks, ensuring regulatory compliance.",
        "Provide ongoing support, monitoring, and refinement for sustained success.",
      ],
    },
    whyChooseUs: {
      point: [
        "1. Proven Success: We deliver tangible results and earned a reputation for excellence.",
        "2. Client-Centric: We prioritize your needs, ensuring personalized and collaborative solutions.",
        "3. Diverse Expertise: Our team offers comprehensive support across various industries and disciplines.",
        "4. Strategic Insight: We provide deep insights and actionable recommendations for sustainable growth.",
        "5. Commitment to Excellence: We deliver results with integrity, transparency, and dedication.",
        "6. Collaborative Partnership: We build trust and work closely with you to achieve your objectives.",
        "7. Innovative Approach: We leverage cutting-edge tools and methodologies to drive innovation.",
        "8. Long-Term Value: We create lasting value and support your success over the long term.",
      ],
    },
  },

  {
    id: 'master-data-migration',
    serviceHeader: "Master Data Migration",
    content:
      "Master Data Management (MDM) is a crucial process that ensures organizations maintain a single, consistent version of key data entities across all their systems and departments. It involves the creation, maintenance, governance, and distribution of master data, which includes critical business entities such as customers, products, employees, suppliers, and more.",
    image: MasterDataManagement,
    keyFeatures: {
      feature: [
        "1. Data Integration: Seamless integration of data from various sources, ensuring consistency and accuracy across the organization.",
        "2. Data Quality Management: Implementing processes to maintain high-quality data through validation, cleansing, and enrichment.",
        "3. Data Governance: Establishing policies, procedures, and controls for managing and protecting master data throughout its lifecycle",
        "4. Data Standardization: Consistent formatting and structuring of data elements to enable interoperability and ease of use.",
        "5. Data Synchronization: Synchronizing master data across different systems and applications to ensure real-time access and consistency.",
        "6. Data Security: Implementing measures to protect sensitive master data from unauthorized access, breaches, and cyber threats.",
        "7. Data Versioning: Maintaining a history of changes to master data elements, allowing for auditing, tracking, and rollback if necessary.",
        "8. Data Enrichment: Enhancing master data with additional attributes or external data sources to provide more comprehensive insights.",
        "9. Data Governance Dashboard: Providing a centralized dashboard for monitoring data quality, compliance, and performance metrics.",
        "10. Scalability and Flexibility: Supporting the scalability and flexibility to accommodate evolving business needs and increasing volumes of data.",
      ],
    },
    infrastructure: {
      title:
        "Our Master Data Management solution is built on a robust and scalable infrastructure, leveraging:",
      points: [
        "Cloud Computing: Deploy MDM solutions rapidly and cost-effectively using scalable cloud platforms.",
        "High Availability: Maintain continuous access to master data with redundant and fault-tolerant systems.",
        "Data Security: Protect sensitive data with advanced encryption, access controls, and continuous monitoring.",
        "Scalable Storage: Manage growing data volumes effortlessly with flexible, scalable storage solutions.",
        "Network Infrastructure: Ensure reliable data transfer and connectivity with top-tier network components.",
      ],
    },
    techStacks: {
      title: "Technologies",
      array: [
        "Databases: Oracle, SQL Server, MongoDB, and Neo4j for robust data management and storage.",
        "Data Integration Tools: Informatica MDM, Talend, and IBM InfoSphere for integrating data from various sources.",
        "Data Quality Tools: SAS Data Management, Ataccama, and DataCleaner for ensuring high-quality, clean data.",
        "API Management: Apigee, MuleSoft, and AWS API Gateway to facilitate secure and scalable data exchange between systems.",
        "Business Intelligence Tools: Tableau, Power BI, and Qlik for advanced data visualization and analytics.",
      ],
    },
    developmentTechniques: {
      title: "Technologies",
      array: [
        "Workflow Automation: Camunda, Airflow, and Microsoft Power Automate for automating data governance workflows.",
        "Security Solutions: Symantec, McAfee, and Azure Security for protecting data against unauthorized access and threats.",
        "Cloud Platforms: AWS, Microsoft Azure, and Google Cloud Platform for scalable, cloud-based data storage and processing.",
        "Development Frameworks: .NET, Java EE, and Node.js for building custom MDM solutions and integrations.",
        "Containerization and Orchestration: Docker, Kubernetes, and OpenShift for deploying and managing containerized applications efficiently.",
      ],
    },
    developmentCycle: {
      title: "Master Data Management Process",
      array: [
        "Understand organization's master data needs and objectives.",
        "Integrate data from various sources to ensure consistency and accuracy.",
        "Implement validation, cleansing, and enrichment processes.",
        "Establish policies, procedures, and controls for managing master data.",
        "Standardize and synchronize master data across systems for real-time access.",
        "Provide ongoing support, monitoring, and refinement for data security and compliance.",
      ],
    },
    whyChooseUs: {
      point: [
        "Expertise: Benefit from our team of experienced professionals who possess deep expertise in implementing MDM solutions across various industries.",
        "Customization: Tailor-made MDM solutions to suit your organization's unique requirements, ensuring maximum efficiency and effectiveness.",
        "Proven Track Record: Trust in our track record of successful MDM implementations, delivering tangible business value and ROI.",
        "Continuous Support: Receive ongoing support and maintenance services to ensure the optimal performance and reliability of your MDM solution.",
        "Future-Proof Solutions: Stay ahead of the curve with MDM solutions that are designed to adapt and scale alongside your organization's evolving needs and challenges.",
      ],
    },
  },

  {
    id: 'data-warehousing-and-business-intelligence',
    serviceHeader: "Data Warehousing & Business Intelligence",
    content:
      "Data Warehousing & Business Intelligence (BI) services are essential for businesses aiming to harness the power of their data to make informed decisions and gain a competitive edge in the market. Our comprehensive suite of services in this domain ensures that your organization's data is efficiently stored, processed, and transformed into actionable insights, empowering you to drive strategic initiatives and achieve your business objectives.",
    image: DataWarehouseManagement,
    keyFeatures: {
      feature: [
        "1. Data Integration: Consolidate data from diverse sources into a unified warehouse for comprehensive analysis.",
        "2. Scalability: Scale storage and processing capabilities to accommodate growing data volumes and user demands.",
        "3. Data Quality Management: Ensure data accuracy, consistency, and reliability through cleansing and validation processes.",
        "4. Advanced Analytics: Perform complex analytics, including predictive modeling, trend analysis, and forecasting.",
        "5. Interactive Dashboards: Visualize data insights through customizable dashboards for intuitive decision-making.",
        "6. Self-Service BI: Empower users with self-service BI tools for ad hoc querying and report generation.",
        "7. Real-time Reporting: Access up-to-date information with real-time reporting capabilities for agile decision-making.",
        "8. Security and Compliance: Implement robust security measures and compliance protocols to safeguard sensitive data.",
        "9. Mobile BI: Enable access to BI insights on-the-go with mobile-friendly dashboards and reporting tools.",
        "10. Data Governance: Establish data governance frameworks to ensure data quality, security, and regulatory compliance.",
      ],
    },
    infrastructure: {
      title:
        "Our data warehousing and BI solutions are built on a modern, scalable architecture that leverages cutting-edge technologies and best practices in data management and analytics. We utilize a combination of on-premises, cloud, and hybrid deployment models to provide flexibility and agility, ensuring that your data infrastructure can adapt to changing business requirements and scale as needed.",
      points: [
        "Database Management Systems: SQL Server, Oracle Database, MySQL",
        "Data Warehousing Platforms: Amazon Redshift, Google BigQuery, Snowflake",
        "ETL Tools: Informatica PowerCenter, Talend, Apache NiFi",
        "Business Intelligence Tools: Tableau, Power BI, Looker",
        "Data Modeling: Erwin Data Modeler, SAP PowerDesigner",
      ],
    },
    techStacks: {
      title: "Technologies",
      array: [
        "Databases: SQL Server, Oracle, MySQL for efficient data management and storage.",
        "Data Warehousing Platforms: Amazon Redshift, Google BigQuery, Snowflake for scalable and high-performance data warehousing.",
        "ETL Tools: Informatica PowerCenter, Talend, Apache NiFi for seamless data extraction, transformation, and loading processes.",
        "Business Intelligence Tools: Tableau, Power BI, Looker for advanced data visualization and analytics.",
        "Data Modeling: Erwin Data Modeler, SAP PowerDesigner for designing and managing data models.",
      ],
    },
    developmentTechniques: {
      title: "Techniques",
      array: [
        "Visualize data insights through customizable, interactive dashboards for intuitive decision-making.",
        "Implement data cleansing and validation processes to ensure accuracy, consistency, and reliability.",
        "Perform complex analytics, including predictive modeling, trend analysis, and forecasting.",
        "Consolidate data from diverse sources into a unified data warehouse for comprehensive analysis.",
        "Understand your data warehousing and BI needs and objectives.",
        "Offer ongoing support, monitoring, and refinement for data security and compliance.",
      ],
    },
    developmentCycle: {
      title: "Data Warehousing & BI Process",
      array: [
        "Understand your data warehousing and BI needs and objectives.",
        "Consolidate data from various sources into a unified warehouse.",
        "Ensure data accuracy, consistency, and reliability through cleansing and validation.",
        "Perform complex analytics like predictive modeling and trend analysis.",
        "Develop interactive, customizable dashboards for visualizing data insights.",
        "Offer ongoing support, monitoring, and refinement for data security and compliance.",
      ],
    },
    whyChooseUs: {
      point: [
        "Expertise: Our team of experienced data engineers, analysts, and architects possesses deep expertise in designing, implementing, and managing data warehousing and BI solutions across various industries and use cases.",
        "Customization: We understand that every business is unique, which is why we tailor our solutions to your specific requirements, ensuring that you get the most value out of your data.",
        "Innovation: We stay abreast of the latest trends and technologies in data management and analytics, enabling us to deliver innovative solutions that drive business growth and transformation.",
        "Collaboration: We believe in building long-term partnerships with our clients, collaborating closely with them to understand their needs, challenges, and goals, and delivering solutions that exceed their expectations.",
        "Support: Our dedicated support team is available round-the-clock to provide assistance and ensure that your data infrastructure operates smoothly and efficiently, enabling you to focus on your core business activities.",
      ],
    },
  },

  {
    id: 'legacy-systems-maintenance-and-migration',
    serviceHeader: "Legacy Systems Maintenance & Migration",
    content:
      'In today\'s rapidly evolving technological landscape, businesses often find themselves grappling with legacy systems that hinder efficiency and scalability. Our "Legacy Systems Maintenance & Migration" service offers a comprehensive solution to address this challenge. We specialize in modernizing outdated systems, ensuring they remain functional, secure, and aligned with your evolving business needs.',
    image: LegacySystems,
    keyFeatures: {
      feature: [
        "1. System Support: We provide comprehensive maintenance and support for your existing legacy systems to ensure uninterrupted operations.",
        "2. Analysis & Optimization: Our experts conduct thorough assessments to identify areas for improvement and optimization within your legacy systems.",
        "3. Migration Planning: We develop tailored migration strategies, including timelines, budgets, and risk assessments, to seamlessly transition from legacy to modern systems.",
        "4. Data Migration: Our team ensures secure and accurate data migration from legacy systems to modern platforms, preserving data integrity throughout the process.",
        "5. Code Refactoring: We update and optimize legacy code to enhance performance, maintainability, and compatibility with contemporary technologies.",
        "6. Testing & Validation: Rigorous testing and validation procedures are conducted to guarantee a smooth transition and minimize disruptions during migration.",
        "7. Training & Documentation: We provide comprehensive training and documentation to empower your team in adopting new systems and processes effectively.",
        "8. Post-Migration Support: Our support continues after migration, addressing any issues promptly and optimizing system performance for long-term success.",
      ],
    },
    infrastructure: {
      title:
        "Our infrastructure is designed to support the complex process of legacy system maintenance and migration:",
      points: [
        "State-of-the-Art Tools: We utilize cutting-edge tools and technologies to streamline the migration process and ensure optimal outcomes.",
        "Scalable Architecture: Our infrastructure is scalable, allowing us to accommodate the unique needs of businesses of all sizes and industries.",
        "Secure Environment: We maintain a secure environment to protect your data throughout the migration process, adhering to the highest security standards.",
      ],
    },
    techStacks: {
      title: "Technologies",
      array: [
        "Programming Languages: Java, C#, COBOL, PL/SQL",
        "Databases: Oracle, IBM DB2, Microsoft SQL Server",
        "Integration Tools: Apache Camel, IBM Integration Bus, Microsoft BizTalk",
        "Data Migration Tools: Informatica PowerCenter, Talend, SSIS (SQL Server Integration Services)",
        "Version Control Systems: Git, SVN",
        "Testing Tools: Selenium, QTP, LoadRunner",
        "Project Management Tools: Jira, Confluence, Trello",
        "Cloud Platforms: AWS, Azure, Google Cloud for hosting and migrations",
        "Containerization: Docker, Kubernetes for modernizing applications during migration",
      ],
    },
    developmentTechniques: {
      title: "How This Works",
      array: [
        "Understand your legacy systems, business needs, and objectives, and conduct a thorough assessment to identify areas for improvement.",
        "Develop tailored migration strategies and ensure secure, accurate data migration to modern platforms.",
        "Update and optimize legacy code, followed by rigorous testing to guarantee a smooth transition.",
        "Provide training and ongoing support to empower your team and optimize system performance for long-term success.",
      ],
    },
    developmentCycle: {
      title: "Data Warehousing & Business Intelligence Process",
      array: [
        "Understand your legacy systems, business needs, and objectives.",
        "Identify areas for improvement and optimization within legacy systems.",
        "Develop tailored migration strategies with timelines, budgets, and risk assessments.",
        "Securely and accurately migrate data from legacy systems to modern platforms.",
        "Update and optimize legacy code for better performance and compatibility.",
        "Conduct rigorous testing to ensure a smooth transition and minimize disruptions.",
        "Provide training and documentation to help your team adopt new systems effectively.",
        "Offer ongoing support to address issues and optimize system performance for long-term success.",
      ],
    },
    whyChooseUs: {
      point: [
        "Expertise and Experience: With years of experience in legacy system maintenance and migration, we have the expertise to handle even the most complex projects.",
        "Tailored Solutions: We understand that every business is unique. Our solutions are tailored to meet your specific requirements, ensuring maximum value and ROI.",
        "Minimal Disruption: We prioritize minimizing disruption to your operations during the migration process, allowing you to focus on your core business activities.",
        "Commitment to Excellence: We are committed to delivering excellence in every aspect of our service, from initial consultation to post-migration support.",
        "Customer Satisfaction: Our track record of satisfied customers speaks for itself. We prioritize customer satisfaction and strive to exceed expectations with every project.",
      ],
    },
  },

  {
    id: 'enterprise-application-integration',
    serviceHeader: "Enterprise Application Integration",
    content:
      "Enterprise Application Integration (EAI) refers to the process of linking various enterprise applications, systems, and software within an organization to enable seamless data flow and communication. It aims to streamline business processes, improve efficiency, and enhance decision-making by ensuring that all systems can communicate and share data effectively.",
    image: EnterpriseApplicationIntegration,
    keyFeatures: {
      feature: [
        "1. Middleware Services: Acts as a bridge to connect different applications, enabling them to communicate without making changes to their individual codebases.",
        "2. Data Integration: Facilitates the consistent access and retrieval of data across multiple systems, ensuring data integrity and synchronization.",
        "3. Real-Time Data Access: Supports real-time data processing, allowing businesses to respond swiftly to changing conditions and make decisions based on the latest information.",
        "4. API Management: Manages APIs to streamline interactions between different software applications and services. This includes API creation, publication, security, and analytics.",
        "5. Process Automation: Automates business processes by integrating separate applications, which reduces manual intervention and increases efficiency.",
        "6. Adaptability and Scalability: Easily adapts to changes in technology and scales to meet growing organizational demands without disrupting existing systems.",
        "7. Message Brokering: Uses a message broker to translate and route messages between different applications, ensuring they can communicate effectively despite using different languages or protocols.",
        "8. Comprehensive Security: Incorporates robust security measures to protect data as it moves between systems, including authentication, encryption, and compliance with various security standards.",
        "9. Centralized Monitoring and Management: Provides tools for monitoring the health and performance of integrations, and managing them centrally to identify and resolve issues quickly.",
      ],
    },
    infrastructure: {
      title:
        "Enterprise Application Integration typically involves the following infrastructure components:",
      points: [
        "Integration Middleware: Central software framework that enables interoperability between different applications and systems.",
        "API Gateways: Manages and secures communication between services, both internally and externally.",
        "Message Queues: Buffers and manages data transmission between systems to ensure reliable message delivery.",
        "Data Integration Tools: Facilitate the extraction, transformation, and loading (ETL) of data across diverse platforms.",
        "Business Process Management (BPM) Tools: Automates and manages business processes to improve operational efficiency.",
        "Cloud Services: Leverages cloud platforms for scalable, flexible, and cost-effective infrastructure solutions.",
        "Service-Oriented Architecture (SOA) Frameworks: Supports service abstraction and reusability across different business applications.",
        "Security Frameworks: Enforces robust data security protocols and ensures compliance with data protection regulations.",
        "Monitoring and Analytics Tools: Provide real-time insights and performance tracking of the integration systems and processes.",
        "Containerization and Virtualization: Employs containers and virtual machines to isolate and deploy services independently, enhancing system resilience and scalability.",
      ],
    },
    techStacks: {
      title: "Technologies",
      array: [
        "Integration Platforms: MuleSoft, Dell Boomi, IBM Integration Bus, Apache Camel.",
        "Messaging Protocols: JMS (Java Message Service), AMQP (Advanced Message Queuing Protocol), MQTT (Message Queuing Telemetry Transport).",
        "API Management: Apigee, Kong, Azure API Management, AWS API Gateway.",
        "Data Transformation: XSLT (Extensible Stylesheet Language Transformations), JSON, XML.",
        "Middleware: Apache Kafka, RabbitMQ, IBM MQ, ActiveMQ.",
      ],
    },
    developmentTechniques: {
      title: "How This Works",
      array: [
        "1. We start with an in-depth consultation to understand your integration needs and develop a tailored strategy.",
        "2. Implement middleware to connect applications and ensure consistent data access and synchronization.",
        "3. Manage APIs and automate business processes to streamline interactions and increase efficiency.",
        "4. Conduct rigorous testing, deploy the solution, and provide ongoing monitoring and support for optimal performance.",
      ],
    },
    developmentCycle: {
      title: "Enterprise Application Integration Process",
      array: [
        "1. Understand enterprise applications, integration needs, and business objectives.",
        "2. Develop a tailored integration strategy with timelines, budgets, and risk assessments.",
        "3. Implement middleware to enable communication between different applications.",
        "4. Ensure consistent access and retrieval of data across systems for data integrity.",
        "5. Manage and secure APIs to streamline interactions between software applications.",
        "6. Automate business processes to reduce manual intervention and increase efficiency.",
        "7. Conduct testing to ensure seamless integration and functionality.",
        "8. Deploy the integrated solution and provide tools for centralized monitoring and management.",
      ],
    },
    whyChooseUs: {
      point: [
        "1. Expertise and Experience: Our team comprises experienced integration specialists with a proven track record of successfully implementing complex integration projects.",
        "2. Customized Solutions: We understand that every organization has unique integration requirements. We offer customized solutions tailored to meet your specific business needs.",
        "3. Scalability: Our integration solutions are designed to scale with your business, accommodating growing data volumes and increasing complexity.",
        "4. Reliability and Performance: We prioritize reliability and performance, ensuring that your integration processes run smoothly and efficiently.",
        "5. Security and Compliance: We follow industry best practices to ensure that your data remains secure and compliant with regulatory requirements.",
        "6. Continuous Support: We provide ongoing support and maintenance to ensure that your integration solution remains up-to-date and optimized.",
      ],
    },
  },
  {
    id:"no-code-web-development",
   serviceHeader:"No Code Website Development",
   content:"Unlock the potential of your online presence with our No Code Website Development services. Our team of experts specializes in creating stunning, user-friendly websites using the latest no-code platforms. Whether you're a small business, a growing startup, or an established enterprise, we tailor our solutions to meet your unique needs and drive your business forward.",
   image:NoCodeWebsiteDevelopment,
   keyFeatures:{
    feature: [
      "1. We craft visually appealing, custom-designed websites that reflect your brand identity and engage your target audience.",
      "2. Our no-code websites are fully responsive, ensuring optimal performance and a seamless user experience on all devices, from desktops to smartphones.",
      "3. We implement advanced SEO strategies to enhance your website's visibility on search engines, driving more organic traffic to your site.",
      "4. Our team can integrate powerful e-commerce features, enabling you to sell products and services online with ease and efficiency.",
      "5. We provide user-friendly content management systems, allowing you to easily update and manage your website content without technical expertise.",
      "6. We prioritize the security of your website, implementing robust measures to protect your data and ensure compliance with industry standards."
  ]
    },
    infrastructure:{
      title:"Our no-code websites are built on reliable and scalable platforms, ensuring optimal performance, flexibility, and adaptability as your business grows."
    },
   techStacks:{
    title:"Development Techniques",
    array: [
      "Design Tools: Adobe, Figma, Canva, Webflow, Bubble",
      "SEO Tools: Google Analytics, SEMrush, Ahrefs",
      "E-commerce: Shopify, WooCommerce, Payment Gateway Integration",
      "Security: SSL Certificates, Regular Backups",
      "Performance: CDN Integration, Image Optimization"
  ]
   },
   developmentTechniques:{
    title:"Development Cycle",
    array: [
      "⋅ We employ agile development practices for flexibility, adaptability, and timely delivery of your no-code website.",
      "⋅ We automate the deployment process to ensure rapid and reliable updates.",
      "⋅ We emphasize quality through TDD practices, minimizing errors and ensuring robust code."
  ]
   },
   developmentCycle:{
    title:"Development Process",
    array:[
      "Requirement Gathering: We work closely with clients to gather requirements and define lead generation goals.",
      "Planning: A detailed plan is created, outlining timelines, milestones, and resource allocation.",
      "Design: Our design team creates visually appealing content and materials based on client feedback.",
      "Development: The development team starts building the lead generation campaigns, following best practices and industry standards.",
      "Testing: Rigorous testing is conducted to ensure lead quality and engagement.",
      "Deployment: The campaigns are launched following a controlled rollout strategy.",
      "Maintenance and Support: We provide ongoing maintenance and support services to address any issues and ensure the campaigns operate smoothly."
  ]

   },
   whyChooseUs:{
     point:[
      "Specialized Expertise: We excel in generating high-quality B2B sales leads, ensuring optimal results.",
      "Targeted Strategies: Our lead generation tactics are customized to meet your business needs and target audience.",
      "Dedicated Team: Our skilled professionals are committed to delivering top-notch solutions, offering continuous support and guidance.",
      "Proven Methods: We utilize effective methods to generate and qualify leads, ensuring they align with your business.",
      "Cost-Effective Solutions: Our services are designed to maximize qualified leads while staying within your budget.",
      "Proven Track Record: With a history of successful lead generation campaigns delivered on time and within budget, you can rely on us to achieve your lead generation goals with precision and excellence."
  ]
   }

  },

  {
    id: 'engineering-and-technical-staffing',
    serviceHeader: "Engineering and Technical Staffing",
    content:
      "Our Engineering & Technical Staffing service is designed to provide your company with top-tier engineering and technical talent to meet your project needs. Whether you're looking to augment your existing team or fill critical roles for a specific project, we offer a comprehensive staffing solution tailored to your requirements.",
    image: EngineeringTechnicalStaffing,
    keyFeatures: {
      feature: [
        "1. Access to Top Talent: Gain access to a vast network of pre-screened engineering and technical professionals, allowing you to quickly find the right candidates for your project.",
        "2. Industry Expertise: Our candidates bring industry-specific knowledge and experience, enabling them to hit the ground running and contribute to your project's success from day one.",
        "3. Customized Solutions: We understand that every project is unique, which is why we work closely with you to tailor our staffing solutions to your specific requirements and goals.",
        "4. Quality Assurance: We maintain high standards of quality and professionalism, ensuring that the candidates we provide not only meet but exceed your expectations.",
        "5. Compliance and Legal Support: We handle all aspects of compliance and legal requirements, alleviating the administrative burden associated with hiring and managing staff.",
      ],
    },
    infrastructure: {
      title: "Our state-of-the-art infrastructure includes:",
      points: [
        "Robust candidate database and applicant tracking system (ATS) to streamline the hiring process.",
        "Advanced screening and assessment tools to evaluate candidates' technical skills, experience, and cultural fit.",
        "Secure and confidential data management systems to protect sensitive information.",
        "Ongoing training and professional development resources to ensure our candidates stay abreast of the latest industry trends and technologies.",
      ],
    },
    techStacks: {
      title: "Technologies",
      array: [
        "Advanced screening and assessment tools to evaluate candidates' technical skills, experience, and cultural fit.",
        "Secure and confidential data management systems to protect sensitive information.",
        "Ongoing training and professional development resources to ensure our candidates stay abreast of the latest industry trends and technologies.",
      ],
    },
    developmentTechniques: {
      title: "How This Works",
      array: [
        "1. Match candidates based on their skills, experience, and industry-specific knowledge to ensure they fit your project needs.",
        "2. Provide continuous support, including compliance and legal assistance, and professional development resources for candidates.",
      ],
    },
    developmentCycle: {
      title: "Engineering and Technical Staffing Process",
      array: [
        "1. Understand your staffing needs, project requirements, and timelines.",
        "2. Source top-tier engineering and technical professionals from our vast network.",
        "3. Conduct technical skills assessments, qualification checks, and experience verification.",
        "4. Tailor staffing solutions to fit short-term, long-term, or temp-to-hire needs.",
        "5. Match candidates based on their skills, experience, and industry knowledge.",
        "6. Provide continuous support, including compliance and legal assistance, and professional development resources.",
      ],
    },
    whyChooseUs: {
      point: [
        "1. Proven Track Record: With years of experience in engineering and technical staffing, we have a proven track record of successfully matching clients with top talent.",
        "2. Client-Centric Approach: We prioritize client satisfaction above all else, going above and beyond to meet your needs and exceed your expectations.",
        "3. Industry Expertise: Our team consists of industry experts who understand the nuances of engineering and technical roles, allowing us to provide targeted solutions tailored to your industry.",
        "4. Cost-Effective Solutions: We offer competitive pricing and flexible staffing options, helping you optimize your workforce while staying within budget.",
        "5. Commitment to Excellence: Our commitment to excellence permeates everything we do, from our rigorous screening process to our ongoing support and communication throughout the staffing process.",
      ],
    },
  },

  {
    id: 'professional-staffing',
    serviceHeader: "Professional Staffing",
    content:
      "At Saintsmen Systems, we understand that finding the right talent is crucial for the success of your business. Our Professional Staffing services are designed to streamline the recruitment process, connecting you with top-tier professionals who align with your company culture and objectives. With our extensive network and industry expertise, we ensure that you have access to the talent you need to drive your business forward.",
    image: ProfessionalStaffing,
    keyFeatures: {
      feature: [
        "1. Industry Expertise: Our team of experienced recruiters specializes in various industries, allowing us to match you with professionals who have the right expertise and knowledge.",
        "2. Quick Turnaround: We understand the urgency of staffing needs, and our efficient processes enable us to identify and onboard qualified candidates quickly.",
        "3. Scalability: Whether you're a startup or a multinational corporation, our scalable solutions adapt to your changing workforce requirements.",
        "4. Cost-Effectiveness: Our competitive pricing models and efficient recruitment methods help you minimize hiring costs without compromising on quality.",
      ],
    },
    infrastructure: {
      title: "Advanced Technology and Facilities",
      points: [
        "We leverage cutting-edge recruitment software and tools to streamline the hiring process, from candidate sourcing to onboarding.",
        "Your sensitive information is safeguarded through encrypted systems and strict data privacy protocols.",
        "Our offices provide a professional environment for client meetings, interviews, and training sessions, ensuring a seamless experience for all stakeholders.",
      ],
    },
    techStacks: {
      title: "How This Works",
      array: [
        "We start with a detailed consultation to understand your staffing needs, company culture, and business objectives.",
        "Our experienced recruiters utilize their industry expertise to identify top-tier candidates from our extensive network.",
        "We conduct thorough screening processes, including resume reviews, interviews, and background checks, to ensure the best fit.",
      ],
    },
    developmentTechniques: {
      title: "How This Works",
      array: [
        "1. Our efficient processes enable us to quickly identify and onboard qualified candidates to meet your urgent staffing needs.",
        "2. We support the seamless onboarding and integration of new hires into your team, ensuring they align with your company culture and objectives.",
      ],
    },
    developmentCycle: {
      title: "Professional Staffing Process",
      array: [
        "1. Understand your staffing needs, project requirements, and timelines.",
        "2. Source top-tier engineering and technical professionals from our vast network.",
        "3. Conduct technical skills assessments, qualification checks, and experience verification.",
        "4. Tailor staffing solutions to fit short-term, long-term, or temp-to-hire needs.",
        "5. Match candidates based on their skills, experience, and industry knowledge.",
        "6. Provide continuous support, including compliance and legal assistance, and professional development resources.",
      ],
    },
    whyChooseUs: {
      point: [
        "1. Proven Track Record: With years of experience in engineering and technical staffing, we have a proven track record of successfully matching clients with top talent.",
        "2. Personalized Approach: We take the time to understand your specific needs and preferences, delivering tailored solutions that exceed your expectations.",
        "3. Dedicated Support: Our team of dedicated recruiters is committed to providing unparalleled support and guidance throughout the recruitment process, offering valuable insights and expertise every step of the way.",
        "4. Quality Assurance: We uphold the highest standards of professionalism and integrity, ensuring that every candidate we recommend meets your criteria and expectations.",
        "5. Long-Term Partnerships: We believe in building lasting relationships with our clients based on trust, transparency, and mutual success, serving as a strategic partner in your talent acquisition journey.",
      ],
    },
  },
];

export default ServicesDetails;
