import { FaArrowRight } from "react-icons/fa";

const SectionTitle = ({sectionText,onClick}) => {
  return (
    <div className={`flex items-center  group cursor-pointer`} onClick={onClick}>
      <div
        className={`px-4 py-2 bg-gray11 hover:bg-[#6837DF] hover:font-semibold hover:text-white rounded-2xl text-sectionText text-center text-xs md:text-base flex justify-center items-center h-[44px] mx-auto  group-hover:text-white transition-all duration-300 ease-in-out`}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          boxSizing: "border-box",
          display: 'flex',
          alignItems: 'center',
        }}
      >
      {sectionText}
        <div className="ml-1 flex items-center opacity-0 group-hover:opacity-100 transition-opacity duration-50 ease-in-out">
          <FaArrowRight />
        </div>
      </div>
    </div>
  );
};

export default SectionTitle;
