import Industries from "../components/industries/Industries";
import { Helmet } from "react-helmet";

export default function IndustriesPage() {
  return (
    <>
      <Helmet>
        <title>Saintsmen Systems | Industries</title>
        <meta
          name="description"
          content="Tailored IT Solutions for diverse industries. We cater to wide range of industries, providing custom software development solutions that meets the specific structure."
        />
      </Helmet>
      <Industries />
    </>
  );
}
