import React from 'react';
import EmployeeHeader from '../assets/employee-information/Emp_info_header.png';
import HeaderNoText from '../assets/employee-information/header_no_text.png'
import MJ from '../assets/employee-information/dp_MJ.png';
import { MdOutlineEmail } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import { GrLocation } from "react-icons/gr";
import { FaLinkedin } from "react-icons/fa";
import EmployeeData from '../data/EmployeeInfo';
import { useParams } from 'react-router-dom';

const EmployeeInfo = () => {
    const {id}=useParams();
    const employee=EmployeeData.find(e=>e.id==id);
  return (
    
    <div className='pb-[40px]'>
        {/* right gradient container */}

      <div className='about-us-gradient-right'>
        {/* left gradient container */}
        <div className='text-white font-semibold text-[40px] leading-tight'>Employee Information</div>
        {/* card starts below */}
        <div className='lg:mx-[5%] mt-[51px] pb-[64px] bg-black border-[1px] border-slate-400 rounded-xl'>
          
            <div className='rounded-t-xl'>
              <img
                className="rounded-t-xl hidden lg:block w-full min-h-[140px] object-cover object-right"
                src={EmployeeHeader}
                alt="Employee Header"
              />
              <img
                className="rounded-t-xl w-full lg:hidden min-h-[140px] object-cover object-right"
                src={HeaderNoText}
                alt="Employee Header"
              />
            </div>
            <div className='flex flex-wrap items-end pl-[6%] space-x-4 space-y-4 mt-[-80px]'>
              <img src={employee?.profilePicture} alt="Profile" className='rounded-full  h-[160px] w-[160px]' />
              <div className='text-white font-bold text-[28px] leading-tight text-start'>
                {employee?.name}
                <div className='font-normal text-[21px]'>{employee?.role}</div>
              </div>
            </div>

            <div className='flex flex-wrap pl-[6%] mt-[64px]'>
  <div className='flex flex-col space-y-[58px] text-[18px] lg:text-[22px] min-w-[50%] pr-4 lg:border-r border-slate-600'>
    <div className='flex items-center space-x-2'>
      <MdOutlineEmail size={40} className='text-white text-[24px] p-2 bg-gray-500 rounded-full' />
      <a href={`mailto:${employee?.email}`} className='text-white font-normal'>
        {employee?.email}
      </a>
    </div>
    <div className='flex items-center space-x-2'>
      <div className='rounded-full bg-gray-500'>
        <BsTelephone size={40} className='text-white text-[24px] p-2' />
      </div>
      <a href={`tel:${employee?.phoneNumber}`} className='text-white font-normal'>
        {employee?.phoneNumber}
      </a>
    </div>
    <div className='flex items-center space-x-2'>
      <GrLocation size={40} className='text-white text-[24px] p-2 bg-gray-500 rounded-full' />
      <span className='text-white font-normal'>{employee?.location}</span>
    </div>
  </div>
  <a 
    href={employee?.linkedIn} 
    target="_blank" 
    rel="noopener noreferrer" 
    className='flex items-start lg:pl-4 mt-[58px] lg:mt-0 lg:w-auto w-full justify-start'
  >
    <FaLinkedin size={40} className='bg-slate-200 rounded-md' />
  </a>
</div>

          
        </div>
      </div>
    </div>
  );
};

export default EmployeeInfo;
