import Mokshith_Profile from '../assets/employee-information/dp_MJ.png';
import Akshay_Profile from '../assets/employee-information/dp_akshay.svg';
import Prathith_Profile from '../assets/employee-information/dp_pratith.png';
import Manjunath_Profile from '../assets/employee-information/dp_manjunath.png'
const EmployeeInfo=[
    {name:"Mokshith Jeevan JS",
    id:"mokshith-jeevan",
    role:"Head of Bussiness",
    phoneNumber:"+91 9742 116367",
    location:"Bengaluru, India",
    email:"mokshith@saintsmen.com",
    linkedIn:"https://www.linkedin.com/in/mokshithjeevan/",
    profilePicture:Mokshith_Profile
    },
    {name:"Akshay Raju",
    id:"akshay-raju",
    role:"Head of Operations",
    phoneNumber:"+91 7259 957050",
    location:"Bengaluru, India",
    email:"akshay.r@saintsmen.com",
    linkedIn:"https://www.linkedin.com/in/akshay-r-aa0097285/",
    profilePicture:Akshay_Profile
    },
    {name:"Prathith KG",
    id:"prathith-kg",
    role:"Marketing Manager",
    phoneNumber:"+91 9845 5082345",
    location:"Bengaluru, India",
    email:"prathith@saintsmen.com",
    linkedIn:"https://www.linkedin.com/in/prathithk/",
    profilePicture:Prathith_Profile
    },
    {name:"Manjunath M",
    id:"manjunath-m",
    role:"Business Development Manager",
    phoneNumber:"+91 7204 141288",
    location:"Bengaluru, India",
    email:"manjunath.m@saintsmen.com",
    linkedIn:"https://www.linkedin.com/in/manjunath-m-07361729b/",
    profilePicture:Manjunath_Profile
    },
]
export default EmployeeInfo;