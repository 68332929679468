import React, { useEffect, useState } from "react";
import Button from "../components/shared/Button";
import axios from "axios";

const RequestComponentPage = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [missingFields, setMissingFields] = useState([]);

  const [submitData, setSubmitData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    partName: "",
    preferredOem: "",
    oemPartNo: "",
    expectedDeliveryDate: "",
    quantity: "",
    testReportRequired: true,
    warranty: "",
    postWarrantySupportRequired: true,
    gradeType: "Commercial",
    shelfType: "Currently Running",
    instructions: "",
    requirementType: "One Time",
    uploadLink: null,
    componentType: "Aircraft",
  });

  const submitCareerDetails = async (e) => {
    e.preventDefault();
    const missing = Object.entries(submitData)
      .filter(([key, value]) => value === "")
      .map(([key]) => key);
    
    if (missing.length > 0 || !file) {
      setMissingFields(missing);
      if (!file) missing.push('file');
      alert(`Please fill out all the entries to proceed! Missing fields: ${missing.join(', ')}`);
      return;
    }

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("file", file);

      const fileResp = await axios.post(
        `${process.env.REACT_APP_API_URL}/uploadDocument`,
        formData
      );
      
      if (!fileResp.data.meta.status) {
        alert("Cannot upload file!");
        setLoading(false);
        return;
      }

      const url = fileResp.data.data.fileUrl;

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/submitComponentInquiry`,
        { ...submitData, uploadLink: url }
      );
      
      setLoading(false);
      if (response.data.meta.status) {
        setIsSubmitted(true);
        setTimeout(() => {
          setIsSubmitted(false);
        }, 5000);
      } else {
        alert(response.data.meta.message);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleChange = (e) => {
    setSubmitData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
    if (missingFields.includes(e.target.id)) {
      setMissingFields(missingFields.filter(field => field !== e.target.id));
    }
  };

  return (
    <div className="about-us-gradient-right">
      <div className="text-white about-us-gradient-left p-4 lg:p-8">
        <h1 className="text-4xl font-semibold text-center text-white mb-4">
          Request a Component
        </h1>
        <p className="mt-2 text-lg font-normal text-center mb-8">
          Submit your component inquiry, and our dedicated sourcing team will efficiently
          <br />
          locate the highest quality parts to meet your requirements.
        </p>
        <form className="flex flex-wrap -mx-2">
          <FormInput
            id="name"
            label="Name"
            value={submitData.name}
            onChange={handleChange}
            placeholder="Enter your name"
            isMissing={missingFields.includes('name')}
          />
          <FormInput
            id="email"
            label="Email"
            type="email"
            value={submitData.email}
            onChange={handleChange}
            placeholder="Enter your Email Id"
            isMissing={missingFields.includes('email')}
          />
          <FormInput
            id="contactNumber"
            label="Contact Number"
            value={submitData.contactNumber}
            onChange={handleChange}
            placeholder="Enter your Contact Number"
            isMissing={missingFields.includes('contactNumber')}
          />
          <FormSelect
            id="componentType"
            label="Component Type"
            value={submitData.componentType}
            onChange={(e) => {
              setSubmitData((prev) => ({
                ...prev,
                componentType: e.target.value,
              }));
            }}
            options={[
              { value: "Aircraft Component", label: "Aircraft Component" },
              { value: "Industrial Machinery Spares", label: "Industrial Machinery Spares" },
              { value: "Marine Grade Component", label: "Marine Grade Component" },
              { value: "Space Grade Component", label: "Space Grade Component" },
              { value: "Parts for Defence Industry", label: "Parts for Defence Industry" },
              { value: "Parts for Research Institute", label: "Part for Research Institute" },
            ]}
            isMissing={missingFields.includes('componentType')}
          />
          <div className="w-full bg-white h-[1px] my-6"/>
          <FormInput
            id="partName"
            label="Part Name"
            value={submitData.partName}
            onChange={handleChange}
            placeholder="Enter Part Name"
            isMissing={missingFields.includes('partName')}
          />
          <FormInput
            id="preferredOem"
            label="Preferred OEM"
            value={submitData.preferredOem}
            onChange={handleChange}
            placeholder="Enter Preferred OEM"
            isMissing={missingFields.includes('preferredOem')}
          />
          <FormInput
            id="oemPartNo"
            label="OEM Part Number"
            value={submitData.oemPartNo}
            onChange={handleChange}
            placeholder="Enter OEM Part Number"
            isMissing={missingFields.includes('oemPartNo')}
          />
          <FormInput
            id="expectedDeliveryDate"
            label="Expected Delivery Date"
            type="date"
            value={submitData.expectedDeliveryDate}
            onChange={handleChange}
            isMissing={missingFields.includes('expectedDeliveryDate')}
          />
          <FormInput
            id="quantity"
            label="Quantity"
            type="number"
            value={submitData.quantity}
            onChange={handleChange}
            placeholder="Enter Quantity"
            isMissing={missingFields.includes('quantity')}
          />
          <FormSelect
            id="testReportRequired"
            label="Test Report Required"
            value={submitData.testReportRequired}
            onChange={(e) => {
              setSubmitData((prev) => ({
                ...prev,
                testReportRequired: e.target.value === "true",
              }));
            }}
            options={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" },
            ]}
            isMissing={missingFields.includes('testReportRequired')}
          />
          <FormInput
            id="warranty"
            label="Warranty"
            value={submitData.warranty}
            onChange={handleChange}
            placeholder="Enter Warranty"
            isMissing={missingFields.includes('warranty')}
          />
          <FormSelect
            id="postWarrantySupportRequired"
            label="Post Warranty Support Required"
            value={submitData.postWarrantySupportRequired}
            onChange={(e) => {
              setSubmitData((prev) => ({
                ...prev,
                postWarrantySupportRequired: e.target.value === "true",
              }));
            }}
            options={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" },
            ]}
            isMissing={missingFields.includes('postWarrantySupportRequired')}
          />
          <FormSelect
            id="gradeType"
            label="Choose Grade Type"
            value={submitData.gradeType}
            onChange={(e) => {
              setSubmitData((prev) => ({
                ...prev,
                gradeType: e.target.value,
              }));
            }}
            options={[
              { value: "Commercial", label: "Commercial" },
              { value: "Military", label: "Military" },
            ]}
            isMissing={missingFields.includes('gradeType')}
          />
          <FormSelect
            id="shelfType"
            label="Choose Shelf Type"
            value={submitData.shelfType}
            onChange={(e) => {
              setSubmitData((prev) => ({
                ...prev,
                shelfType: e.target.value,
              }));
            }}
            options={[
              { value: "Currently Running", label: "Currently Running" },
              { value: "End Of Life", label: "End Of Life" },
            ]}
            isMissing={missingFields.includes('shelfType')}
          />
          <FormSelect
            id="requirementType"
            label="Choose Requirement Type"
            value={submitData.requirementType}
            onChange={(e) => {
              setSubmitData((prev) => ({
                ...prev,
                requirementType: e.target.value,
              }));
            }}
            options={[
              { value: "One Time", label: "One Time" },
              { value: "Continuous", label: "Continuous" },
            ]}
            isMissing={missingFields.includes('requirementType')}
          />
          <div className="w-full lg:w-1/2 px-2 mb-4 text-left">
            <label
              htmlFor="file"
              className="block font-normal text-lg mb-2"
            >
              Upload Image/PDF
            </label>
            <input
              type="file"
              id="file"
              onChange={(e) => {
                setFile(e.target.files[0]);
                if (missingFields.includes('file')) {
                  setMissingFields(missingFields.filter(field => field !== 'file'));
                }
              }}
              className={`w-full p-4 rounded-md bg-brandBackground ${missingFields.includes('file') ? 'border-red-500' : 'border-[#474747]'}`}
            />
          </div>
          <FormTextarea
            id="instructions"
            label="Instructions"
            value={submitData.instructions}
            onChange={handleChange}
            placeholder="Enter Instructions"
            isMissing={missingFields.includes('instructions')}
          />

          <div className="w-full px-2 mb-4 flex justify-center">
            {isSubmitted ? (
              <div className="text-white">We'll get back to you soon!</div>
            ) : (
              <Button
                buttonText={loading ? "Submitting..." : "Request a Quote"}
                onClick={submitCareerDetails}
                className="w-full lg:w-[320px]"
                loading={loading}
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

const FormInput = ({ id, label, type = "text", isMissing, ...props }) => (
  <div className="w-full lg:w-1/2 px-2 mb-4 text-left">
    <label htmlFor={id} className="block font-normal text-lg mb-2">
      {label}
    </label>
    <div className={`relative ${type === 'date' ? 'date-input-wrapper' : ''}`}>
      <input
        type={type}
        id={id}
        className={`w-full p-4 h-[60px] rounded-md bg-brandBackground border ${
          isMissing ? 'border-red-500 placeholder-red-500' : 'border-[#474747]'
        } ${type === 'date' ? 'date-input' : ''}`}
        {...props}
      />
      {type === 'date' && (
        <style jsx>{`
          .date-input-wrapper::-webkit-calendar-picker-indicator {
            background: transparent;
            bottom: 0;
            color: transparent;
            cursor: pointer;
            height: auto;
            left: auto;
            position: absolute;
            right: 0;
            top: 0;
            width: auto;
          }
          .date-input::-webkit-calendar-picker-indicator {
            color: rgba(0, 0, 0, 0);
            opacity: 1;
            display: block;
            background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z'/%3E%3C/svg%3E") no-repeat;
            width: 24px;
            height: 24px;
            border-width: thin;
          }
        `}</style>
      )}
    </div>
  </div>
);

const FormSelect = ({ id, label, options, isMissing, ...props }) => (
  <div className="w-full lg:w-1/2 px-2 mb-4 text-left">
    <label htmlFor={id} className="block font-normal text-lg mb-2">
      {label}
    </label>
    <select
      id={id}
      className={`w-full p-4 h-[60px] rounded-md bg-brandBackground border ${
        isMissing ? 'border-red-500' : 'border-[#474747]'
      }`}
      {...props}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

const FormTextarea = ({ id, label, isMissing, ...props }) => (
  <div className="w-full lg:w-1/2 px-2 mb-4 text-left">
    <label htmlFor={id} className="block font-normal text-lg mb-2">
      {label}
    </label>
    <textarea
      id={id}
      className={`w-full p-4 h-[120px] rounded-md bg-brandBackground border ${
        isMissing ? 'border-red-500 placeholder-red-500' : 'border-[#474747]'
      }`}
      {...props}
    ></textarea>
  </div>
);

export default RequestComponentPage;