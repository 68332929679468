import BharathElectronics from "../../assets/brand-logos/bharath-electronics.svg";
import FreezeBooking from "../../assets/brand-logos/freeze-booking.svg";
import Logissy from "../../assets/brand-logos/logissy.svg";
import Maersk from "../../assets/brand-logos/maersk.svg";
import Snactivate from "../../assets/brand-logos/snactivate.png";
import Fifo from "../../assets/brand-logos/fifo future.png";
import FreightBro from "../../assets/brand-logos/freightbro.png";
import Keonics from "../../assets/brand-logos/keonics white.png";
import Tnega from "../../assets/brand-logos/tnegalogo.png";
import IndoFuji from "../../assets/brand-logos/indo fuji logo.png";

const TrustedCompany = () => {
  const BrandLogos = [
    BharathElectronics,

    FreezeBooking,
 

    Snactivate,
  ];

  const duplicatedBrandLogos = [...BrandLogos, ...BrandLogos,...BrandLogos];
  return (
    <>
      <p className="text-base md:text-2xl font-medium mt-12 text-white">
        Trusted by Global Brands - Building trust since 2023
      </p>
      <p className="text-neutral3 font-normal text-lg mt-4">
        Saintsmen has been chosen by 50+ Brands, enterprises and Startups
      </p>
      <div className="relative w-full h-auto flex items-center justify-center bg-black py-5">
        <div className="flex w-full overflow-hidden relative">
          <div className="flex items-center whitespace-nowrap animate-scrollX">
            {duplicatedBrandLogos.map((el, index) => (
              <div
                key={index}
                className="grid place-items-center w-[clamp(10rem,1rem+10vmin,20rem)] px-4 bg-brandBackground ml-2 mr-2 h-16 shadow-innerRight"
              >
                <img
                  src={el}
                  alt="Brand Logo"
                  className="object-contain w-full h-full"
                  loading="lazy"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="absolute inset-0 pointer-events-none "></div>
      </div>
    </>
  );
};

export default TrustedCompany;
