import FreezeBookingImage from "../assets/case-study/freezebooking-image.svg";
import FreezeBookingLogo from "../assets/case-study/freezebooking-logo.svg";
import LogissyImage from "../assets/case-study/logissy-image.svg";
import LogissyLogo from "../assets/case-study/logissy-logo.svg";
import FoodCommerceAppImage from "../assets/case-study/food-commerce-app-image.svg";
import FoodCommerceAppLogo from "../assets/case-study/food-commerce-app-logo.svg";
import ParkingAppImage from "../assets/case-study/parking-app-image.svg";
import ParkingAppLogo from "../assets/case-study/parking-app-logo.svg";
import VehicleTransitSolutionsImage from "../assets/case-study/vehicle-transit-solutions-image.svg";
import VehiclTransitSolutionsLogo from "../assets/case-study/vehicle-transit-solutions-logo.svg";
import ResidentTransportSolutionsImage from "../assets/case-study/resident-transport-solutions-image.svg";
import ResidentTransportSolutionsLogo from "../assets/case-study/resident-transport-solutions-logo.svg";

const CaseStudies = [
  {
    id: 'freeze-booking',
    name: "Freeze Booking",
    image: FreezeBookingImage,
    logo: FreezeBookingLogo,
    primaryHeader: "Simplifying Travel & Boosting Engagement",
    primaryContent: "Company: Freeze Booking (Online Travel Booking Platform)",
    secondaryHeader: "What they needed",
    secondaryContent:
      "To simplify complex booking processes and enhance user engagement.",
    tertiaryHeader: "How we helped",
    tertiaryContent:
      "We built a user-friendly platform with cutting-edge technology and intuitive UI/UX design",
    results: [
      {
        value: "35% reduction in average booking time",
        description:
          "Streamlined search and booking functionalities made the process significantly faster.",
      },
      {
        value: "20% increase in user engagement",
        description:
          "Intuitive UI/UX design kept users engaged and exploring travel options.",
      },
      {
        value: "4.8 out of 5 star average customer satisfaction rating",
        description:
          "Personalized recommendations and a user-friendly platform led to a surge in customer satisfaction.",
      },
    ],
  },

 

  {
    id: 'food-commerce-app',
    name: "Food Commerce App",
    image: FoodCommerceAppImage,
    logo: FoodCommerceAppLogo,
    primaryHeader: "Redefining Food Commerce Experiences",
    primaryContent: "Company: Online Food Commerce Platform",
    secondaryHeader: "What they needed",
    secondaryContent:
      "Transform the home cooking world with an innovative culinary application",
    tertiaryHeader: "How we helped",
    tertiaryContent:
      "We developed a digital platform with real time analysis that aims to encourage cooks and improve users' experiences with home cooking.",
    results: [
      {
        value: "18% Efficiency Gain: ",
        description:
          "With our solution, home cooking has been redefined and efficiency has increased by 18%. We've reduced the amount of time spent on meal preparation and order administration with simpler procedures and improved functionality.",
      },
      {
        value: "13% Reduction in Order Processing Time:",
        description:
          "Kai Tuttu has reduced order processing times by 13% by streamlining workflows and improving procedures. Our dedication to improving user experience and operational effectiveness is demonstrated by this efficiency increase.",
      },
      {
        value: "9% Rise in Customer Retention:",
        description:
          "Our commitment to providing outstanding customer service and value-added services has resulted in a 9% rise in client retention. This measure highlights our capacity to create lasting connections and encourage loyalty in the food sector.",
      },
    ],
  },

  {
    id: 'parking-app',
    name: "Parking App",
    image: ParkingAppImage,
    logo: ParkingAppLogo,
    primaryHeader: "Revolutionizing Urban Parking & Streamlining Mobility",
    primaryContent: "Company: Online Travel Platform",
    secondaryHeader: "What they needed",
    secondaryContent:
      "A mobile parking system designed to improve accessibility and streamline urban parking in congested city centres and popular tourist locations.",
    tertiaryHeader: "How we helped",
    tertiaryContent:
      "With the help of our advanced smartphone app, users can quickly locate, reserve, and manage parking spaces, completely changing the way people park.",
    results: [
      {
        value: "20% Increase in Parking Efficiency",
        description:
          "The app has seen an amazing 20% boost in parking efficiency because to our creative approach. By maximising parking utilisation and cutting down on time spent looking for a spot, our approach improves traffic flow.",
      },
      {
        value: "15% rise in User Satisfaction",
        description:
          "The smooth booking process and user-friendly interface of the app have resulted in a 15% rise in customer satisfaction. Consumers value our app's convenience and use since it improves their entire parking experience.",
      },
      {
        value: "10% Expansion in Market Share",
        description:
          "Our client's market share has increased by 10% as a result of the app deployment, securing their place as the industry leader in urban parking solutions. This expansion demonstrates our capacity to provide creative solutions that address the changing demands of urban mobility.",
      },
    ],
  },

  {
    id: 'vehicle-transit-solutions',
    name: "Vehicle Transit Solutions",
    image: VehicleTransitSolutionsImage,
    logo: VehiclTransitSolutionsLogo,
    primaryHeader: "Transforming Automobile Imports to Increase Productivity",
    primaryContent: "Company: Online Travel Platform",
    secondaryHeader: "What they needed",
    secondaryContent:
      "Modernizing vehicle imports by streamlining processes for seamless efficiency.",
    tertiaryHeader: "How we helped",
    tertiaryContent:
      "We started transforming the difficulties involved in importing cars using Vin2 trade software solutions. Our goal was to streamline and improve the procedure while enhancing user experience with innovative capabilities.",
    results: [
      {
        value: "20% Efficiency Enhancement",
        description:
          "Our comprehensive approach produced an outstanding 20% increase in productivity. Through process simplification and integration of the latest capabilities, we have successfully reduced administrative cost and enhanced efficiency.",
      },
      {
        value: "15% Increase in User Engagement",
        description:
          "The developed functionality and visually compelling design of our platform have resulted in a significant 15% increase in user engagement. This demonstrates our commitment to providing trade solutions that put the needs and satisfaction of the user first.",
      },
      {
        value: "13% Rise in Customer Retention",
        description:
          "Our dedication to provide exceptional customer service and value-added services has led to a 13% increase in customer retention.",
      },
    ],
  },

  {
    id: 'resident-transport-solutions',
    name: "Resident Transport Solutions",
    image: ResidentTransportSolutionsImage,
    logo: ResidentTransportSolutionsLogo,
    primaryHeader: "Redefining Luxury Driving and Urban Transportation",
    primaryContent: "Company: Online Travel Company",
    secondaryHeader: "What they needed",
    secondaryContent:
      "Providing effortless access to a fleet of BMWs for building residents.",
    tertiaryHeader: "How we helped",
    tertiaryContent:
      "Collectively, we developed an ultra-modern smartphone app that improved resident satisfaction and made car reservations easier.",
    results: [
      {
        value: "25% Increase in Vehicle Access Efficiency Enhancement",
        description:
          "25% more vehicle access efficiency has been achieved with our system thanks to innovative reservation management. This efficient method has increased resident convenience while reducing wait times.",
      },
      {
        value: "15% Increase in Resident Satisfaction",
        description:
          "Our platform's expanded functionality and eye-catching appearance have increased user engagement by a noticeable 15%. This displays our dedication to offering trade solutions that prioritise the user's demands and happiness.",
      },
      {
        value: "30% Reduction in Vehicle Downtime",
        description:
          "We've reduced vehicle downtime by 30% by streamlining vehicle maintenance and scheduling. By ensuring people have access to cars whenever they need them, this maximises their satisfaction and utilisation of them.",
      },
    ],
  },
];

export default CaseStudies;
