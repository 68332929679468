import React from "react";
import Faq from "../components/shared/FAQ";
import { Helmet } from "react-helmet";

const FAQsPage = () => {
  return (
    <div>
      <Helmet>
        <title>Saintsmen Systems | FAQ</title>
        <meta
          name="description"
          content="Find answers to common questions: Visit our FAQ for details on our services, policies, and more."
        />
      </Helmet>
      <Faq />
    </div>
  );
};

export default FAQsPage;
